import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LZString from 'lz-string';

const WhatsAppModal = ({ open, onClose, onSave, initialWsp }) => {
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({ phone: false });
  
  useEffect(() => {
      if (initialWsp) {
      setPhone(initialWsp.phone || '');
      setMessage(initialWsp.message || '');
      }
  }, [initialWsp]);

  const handleSave = () => {
    let hasError = false;

    if (!phone || phone.length < 5) {
    setErrors((prev) => ({ ...prev, phone: true }));
    hasError = true;
    } else {
    setErrors((prev) => ({ ...prev, phone: false }));
    }

    if (hasError) return;

    const dataToCompress = {
        phone,
        message,
    };
    const compressedData = LZString.compressToBase64(JSON.stringify(dataToCompress));
    onSave(compressedData);
    onClose();
  };

  const handleTestLink = () => {
    if (!phone || phone.trim() === '' || phone.length < 5) {
        alert('Por favor, ingresa un número de teléfono válido antes de probar el enlace.');
        return;
    }

    const encodedMessage = message ? encodeURIComponent(message) : '';
    const whatsappUrl = `https://wa.me/${phone}${encodedMessage ? `?text=${encodedMessage}` : ''}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" mb={2}>
          Configuración de WhatsApp
        </Typography>

        <PhoneInput
          country="pe"
          value={phone}
          onChange={(value) => setPhone(value)}
          inputStyle={{ width: '100%' }}
          placeholder="Ingresa el número de teléfono"
          isValid={!errors.phone}
        />
        {errors.phone && (
          <Typography color="error" variant="caption">
            Por favor, ingresa un número de teléfono válido.
          </Typography>
        )}

        {/* Mensaje personalizado */}
        <TextField
          fullWidth
          label="Mensaje (opcional)"    
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          margin="normal"
          placeholder="Ingresa el mensaje para WhatsApp"
        />

        {/* Botones */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Generar Enlace
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleTestLink}
          >
            Probar Enlace
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default WhatsAppModal;