import React, { useState, useEffect } from "react";
import { Description } from "@mui/icons-material";

const Ui1 = ({id,title,description,button,buttonColor,url1,reportUrl,button2,buttonColor2,url2,fontColor1,fontColor2}) => {

  return (

    <section className="text-gray-600 body-font  w-[90%] mx-auto mb-4 ">
      <div className="container mx-auto flex pt-5 md:py-5 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-4 md:mb-0 items-center text-center">
            <h1 className="title-font text-2xl	 md:text-3xl mb-4 font-semibold text-gray-900">
                {title}  
            </h1>
            <p className="mb-8 leading-relaxed text-sm md:text-base text-justify  md:text-left max-h-[120px]">
                {description}
            </p>
          <div className="flex justify-center gap-2">
            <button className="inline-flex  bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                style={{ backgroundColor: buttonColor, color: fontColor1 }}
                onClick={() => window.location.href = url1}
                >
              {button}
            </button>

            <button
              className="inline-flex bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              style={{ backgroundColor: buttonColor2,color: fontColor2  }}
              onClick={() => window.location.href = url2}
            >
              {button2}
            </button>

            
          </div>
        </div>
        <div className=" lg:w-full md:w-1/2 w-full m-auto ">
            <iframe
                title="Power BI Report"
                src={reportUrl}
                width="100%"
                className="h-[200px] md:min-h-[230px] lg:h-[400px] max-h-[400px] "
                style={{ border: "none" }}
                allowFullScreen
            />
        </div>
      </div>
    </section>

  );
};

export default Ui1;
