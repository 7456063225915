// src/components/ProductCard.js
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import ProductTiktokShare from "./ProductTiktokShare"

const ProductCard = ({ isTikTokEnabled ,product, onEdit , onDelete ,isAdminRoute ,storeName}) => {

  const { generateSlug } = require('../utils/slugGenerator');
  const { id, images, name, price, oldPrice , paymentLink,description} = product;
  const slug = generateSlug(name);

 

  const productLink = isAdminRoute
    ? `/${storeName}/administrar/producto/${id}/${slug}`
    : `/producto/${id}/${slug}`;

  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-6" >
      <div className="single-product-box">
        <div className="product-image">
         <div>
              <Link to={productLink}>
                      {images && images.length > 0 ? (
                        <img src={images[0]} alt={name} />
                      ) : (
                        <img
                        src={`${process.env.PUBLIC_URL}/assets/img/placeholder.png`}
                        alt="Placeholder"
                        />
                      )}
                </Link>
          </div>
          
          <ul>
            <li>
              <a href="#" onClick={(e) => {e.preventDefault();onEdit(product);}} title="Edit Product">
                <EditIcon />
              </a>
            </li>
            <li>
              <a href="#" onClick={() => onDelete(product.id)} title="Eliminar Producto">
                <DeleteIcon />
              </a>
            </li>
            <li>
                {isTikTokEnabled && (
                  <ProductTiktokShare
                    title={product.name}
                    description={product.description}
                    images={product.images}
                    storeName={storeName}
                  />
                )}

            </li>
          </ul>
        </div>


        <div className="product-content">
          <h3>{name}</h3>
          <div className="product-price">
            {oldPrice && <span className="old-price">${oldPrice}</span>}
            <span className="new-price">${price}</span>
          </div>
          <a href={paymentLink} target="_blank" className="btn btn-light">
          Comprar Ahora
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
