import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Tooltip,
  Fab,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import axios from "axios";


const ProductTiktokShare = ({ title, description, images ,storeName}) => {
  
  const [open, setOpen] = useState(false);
  const accessToken = localStorage.getItem(`${storeName}_tiktok_access_token`);
  const [formValues, setFormValues] = useState({
    title: title || "",
    description: description || "",
    disable_comment: false,
    privacy_level: "SELF_ONLY",
    auto_add_music: false,
    photo_cover_index: 0,
    photo_images: images || [""],
    post_mode: "MEDIA_UPLOAD",
  });

  useEffect(() => {
   
    setFormValues((prevState) => ({
      ...prevState,
      title: title || "",
      description: description || "",
      photo_images: images || [""],
    }));
  }, [title, description, images]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };


  const updatePhotoImage = (index, value) => {
    const updatedImages = [...formValues.photo_images];
    updatedImages[index] = value;
    setFormValues({ ...formValues, photo_images: updatedImages });
  };

  const handleSubmit = async () => {
    const token = accessToken; 
    const body = {
      post_info: {
        title: formValues.title,
        description: formValues.description,
        disable_comment: formValues.disable_comment,
        privacy_level: formValues.privacy_level,
        auto_add_music: formValues.auto_add_music,
      },
      source_info: {
        source: "PULL_FROM_URL", 
        photo_cover_index: formValues.photo_cover_index,
        photo_images: formValues.photo_images,
      },
      post_mode: formValues.post_mode,
      media_type: "PHOTO", 
    };  

    try {
      const response = await axios.post(
        "https://us-central1-oneclick-market.cloudfunctions.net/sharetiktok", 
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Respuesta de Firebase:", response.data);
      alert("Publicación compartida con éxito en TikTok!");
    } catch (error) {
      console.error("Error al compartir en TikTok:", error.response?.data || error.message);
      alert("Ocurrió un error al compartir en TikTok.");
    }
    handleClose();
  };

  return (
    <>
      <Tooltip title="Compartir en TikTok" arrow>
        <Fab
          color="primary"
          aria-label="add"
          style={{
            backgroundColor: "white",
            boxShadow: 1,
            overflow: "hidden",
            width: "40px",
            height: "40px",
          }}
          onClick={handleOpen}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/tiktokLogo.png`}
            alt="TikTok Logo"
            style={{
              width: "30px",
              height: "35px",
            }}
          />
        </Fab>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "800px",
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography variant="h6" mb={2}>
            Compartir en TikTok
          </Typography>
          <Grid container spacing={2}>
            {/* Título */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Título"
                name="title"
                value={formValues.title}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
            </Grid>

            {/* Descripción */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                multiline
                rows={3}
                sx={{ mb: 2 }}
              />
            </Grid>

            {/* Nivel de privacidad */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Nivel de privacidad</InputLabel>
                <Select
                  name="privacy_level"
                  value={formValues.privacy_level}
                  onChange={handleChange}
                >
                  <MenuItem value="PUBLIC_TO_EVERYONE">Público</MenuItem>
                  <MenuItem value="SELF_ONLY">Solo yo</MenuItem>
                  <MenuItem value="MUTUAL_FOLLOW_FRIENDS">Amigos</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Índice de portada */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Índice de portada"
                name="photo_cover_index"
                type="number"
                value={formValues.photo_cover_index}
                onChange={handleChange}
              />
            </Grid>

            {/* Opciones adicionales */}
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="disable_comment"
                    checked={formValues.disable_comment}
                    onChange={handleChange}
                  />
                }
                label="Deshabilitar comentarios"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="auto_add_music"
                    checked={formValues.auto_add_music}
                    onChange={handleChange}
                  />
                }
                label="Agregar música automáticamente"
              />
            </Grid>

            {/* URLs de imágenes */}
            <Grid item xs={12}>
              <Typography variant="body1" mb={1}>
                URLs de imágenes
              </Typography>
              {formValues.photo_images.map((image, index) => (
                <TextField
                  key={index}
                  fullWidth
                  placeholder={`Imagen ${index + 1}`}
                  value={image}
                  onChange={(e) => updatePhotoImage(index, e.target.value)}
                  sx={{ mb: 1 }}
                />
              ))}
            </Grid>

            {/* Modo de publicación */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Modo de publicación</InputLabel>
                <Select
                  name="post_mode"
                  value={formValues.post_mode}
                  onChange={handleChange}
                >
                  <MenuItem value="MEDIA_UPLOAD">Programar</MenuItem>
                  <MenuItem value="DIRECT_POST">Publicar ahora</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Botones */}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Compartir
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ ml: 2 }}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ProductTiktokShare;
