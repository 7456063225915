import React from 'react';
import CardServicio1 from './CardServicio1'; // Ojo: Asegúrate que el archivo y el nombre de export sean correctos
import CardServicio2 from './CardServicio2';
import CardServicio3 from './CardServicio3';
import CardServicio4 from './CardServicio4';

const CardServicio = ({
  designVariant,
  id,
  titulo,
  descripcion,
  icono,
  color,
  textoEnlace,
  href,
  hasLink
}) => {
  switch (designVariant) {
    case 'CardServicio1':
      return (
        <CardServicio1
          id={id}
          titulo={titulo}
          descripcion={descripcion}
          icono={icono}
          color={color}
          textoEnlace={textoEnlace}
          href={href}
          hasLink={hasLink}
        />
      );
    case 'CardServicio2':
      return (
        <CardServicio2
          id={id}
          titulo={titulo}
          descripcion={descripcion}
          icono={icono}
          color={color}
          textoEnlace={textoEnlace}
          href={href}
          hasLink={hasLink}
        />
      );
    case 'CardServicio3':
      return (
        <CardServicio3
          id={id}
          titulo={titulo}
          descripcion={descripcion}
          icono={icono}
          color={color}
          textoEnlace={textoEnlace}
          href={href}
          hasLink={hasLink}
        />
      );
    case 'CardServicio4':
      return (
        <CardServicio4
          id={id}
          titulo={titulo}
          descripcion={descripcion}
          icono={icono}
        />
      );
    default:
      return null;
  }
};

export default CardServicio;