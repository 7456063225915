import React from 'react';

const CardServicio4 = ({ icono, titulo, descripcion }) => {
    return (
        <div className="flex justify-center px-[20px] md:px-[40px] flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6 md:px-[40px] mb-3 lg:mb-0">
            <div className="flex flex-col text-center items-start">
                <div className="inline-flex items-start justify-center text-indigo-400 mb-3 flex-shrink-0 bg-white">
                    <img
                        src={icono}
                        alt={`${titulo} icon`}
                        className="object-contain w-[75px] h-[75px]"
                    />
                </div>
                <div className="flex-grow mb-9 md:mb-0 text-left text-[#000E29]">
                    <h2 className="text-lg title-font font-bold mb-2">
                        {titulo}
                    </h2>
                    <p className="md:leading-relaxed text-base text-[#000E29] font-normal leading-normal">{descripcion}</p>
                </div>
            </div>
        </div>
    );
};

export default CardServicio4;