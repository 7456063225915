import React, { useState, useEffect } from 'react';
import WhatsAppButton from './components/WhatsAppButton';
import WhatsAppModal from './components/WhatsAppModal';
import decompressWsp from './utils/decompressWsp';
import compressWsp from './utils/compressWsp';
import { saveToFirestore } from '../../utils/firebaseUtils';

const WhatsAppManager = ({ storeName, isAdminRoute, compressedWsp }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [whatsApp, setWhatsApp] = useState({});
  
  // Al montar, descomprimimos la data si existe
  useEffect(() => {
    if (compressedWsp) {
      try {
        const decompressedData = decompressWsp(compressedWsp);
        setWhatsApp(decompressedData);
      } catch (error) {
        console.error('Error al descomprimir datos de WhatsApp:', error);
      }
    }
  }, [compressedWsp]);

  // Evento de clic en el botón (depende de si es admin o no)
  const handleWhatsAppClick = () => {
    if (isAdminRoute) {
      setIsModalOpen(true);
    } else {
      const { phone, message } = whatsApp;
      if (!phone || phone.trim() === '') {
        alert('Por favor, configure un número de teléfono válido.');
        return;
      }
      const encodedMessage = message ? encodeURIComponent(message) : '';
      const whatsappUrl = `https://wa.me/${phone}${encodedMessage ? `?text=${encodedMessage}` : ''}`;
      window.open(whatsappUrl, '_blank');
    }
  };
  
  // Guardar configuración (se llama desde el modal)
  const handleSave = async (newCompressedWsp) => {
    try {
      // Guardo el string comprimido en Firestore
      await saveToFirestore(storeName, 'config', { whatsApp: newCompressedWsp });
      // Actualizo mi state local con la versión descomprimida
      const decompressedData = decompressWsp(newCompressedWsp);
      setWhatsApp(decompressedData);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al guardar el servicio:', error);
    }
  };

  // Cuando el usuario suelta el botón arrastrable
  const handleDragStop = async (newPos) => {
    try {
      // Actualizamos el objeto completo
      const updatedWhatsApp = {
        ...whatsApp,
        buttonPosition: newPos,
      };
      // Lo comprimimos
      const newCompressed = compressWsp(updatedWhatsApp);
      // Lo guardamos
      await saveToFirestore(storeName, 'config', { whatsApp: newCompressed });
      // Actualizamos estado local
      setWhatsApp(updatedWhatsApp);
    } catch (error) {
      console.error('Error al guardar posición del botón:', error);
    }
  };

  // Extraemos la posición actual (o la inicial, si no existe)
  const buttonPosition = whatsApp.buttonPosition || { x: 0, y: 0 };
  console.log("buttonPosition", buttonPosition);

  return (
    <>
      {/* Botón flotante y arrastrable */}
      <WhatsAppButton 
        isAdminRoute={isAdminRoute}
        position={buttonPosition}
        onDragStop={handleDragStop}
        onClick={handleWhatsAppClick}
      />

      {/* Modal para configurar phone/message si es admin */}
      {isAdminRoute && (
        <WhatsAppModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
          initialWsp={whatsApp}
        />
      )}
    </>
  );
};

export default WhatsAppManager;
