// src/pages/LoginArea.js
import React from 'react';
import LoginForm from './LoginForm';
import NewCustomer from './NewCustomer';
import LogoPreview from '../Logo/components/LogoPreview';

const LoginArea = () => (


  <section className="login-area "> 
  
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12">
            <center><LogoPreview logo="assets2/img/logo/logoblanco.png" width="250" height="250" /></center>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <LoginForm />
        </div>
        <div className="col-lg-6 col-md-12">
          <NewCustomer />
        </div>
      </div>
    </div>
  </section>
);

export default LoginArea;
