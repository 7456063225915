import React from 'react';
import {  Button } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const BeneficiosAddButton = ({ onClick }) => {
  return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<DesignServicesIcon />}
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '1rem',
          right: '-6.5rem',                
          transition: 'right 0.3s ease',
          borderTopRightRadius: 0,  
          zIndex: 2,   
          borderBottomRightRadius: 0, 
          background: 'linear-gradient(90deg, #a000b8, #5724ff 20%, #003efa)', 
          borderRight: 'none',
          '&:hover': {
            right: '0rem',             
            background: 'linear-gradient(90deg, #a000b8, #5724ff 50%, #003efa)', 
          },
        }}
      >
        Beneficios
      </Button>
  );
};

export default BeneficiosAddButton;


{/*
import React from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const BeneficiosAddButton = ({ user, onClick }) => {
  return (
    <>
      {user && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={onClick}
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '-4.9rem', 
            transition: 'right 0.3s ease', 
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 'none',
            background: 'linear-gradient(90deg, #a000b8, #5724ff 50%, #003efa)', // Aplicar gradiente
            '&:hover': {
              right: '0rem', // Nueva posición al hacer hover
              background: 'linear-gradient(90deg, #a000b8, #5724ff 50%, #003efa)', // Reaplica gradiente en hover
            },
          }}



          
        >
          Beneficios
        </Button>
      )}
    </>
  );
};

export default BeneficiosAddButton;

  */}