import React, { useState } from 'react';
import { IconButton , CircularProgress} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import FeatureToggleModal from './FeatureToggleModal'; // Importa tu modal
import useStore from '../../../../hooks/useStore';
import decompress from '../../../../utils/decompress';

const SectionStoreButton = ({ onSave , store }) => {

  
  const {
    loading,
    error,
    storeConfig,
    getStoreConfig,
    features
  } = useStore();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = async () => {
    try {
      getStoreConfig(store.nombre.toLowerCase());
    } catch (err) {
      console.error('Error al cargar los datos:', err);
      setOpen(false); // Abre el modal
    } finally {
      setOpen(true); // Abre el modal
    }
  };
   
  

  return (
    <>
      {/* Botón para abrir el modal */}
      <IconButton
        onClick={handleOpen}
        disabled={loading} // Deshabilitar mientras está cargando
        sx={{
          backgroundColor: 'white',
          boxShadow: 1,
          '&:hover': { backgroundColor: 'lightgrey' },
        }}
      >
        {loading ? <CircularProgress size={20} /> : <TuneIcon />}
      </IconButton>
      
      {/* Modal de configuración de features y plugins */}
      {storeConfig && features && (
        <FeatureToggleModal 
          open={open} 
          onClose={handleClose} 
          onSave={onSave} 
          store={store}
          decompressFeatures={features}
        />
      )}
      
    </>
  );
};

export default SectionStoreButton;

