import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDocumentById } from '../../utils/firebaseUtils';
import LZString from 'lz-string';
import { CircularProgress, Box, Typography } from '@mui/material';
import Footer from '../Footer/components/Footer';
import DetalleProductoPreview from './components/DetalleProductoPreview';
import MetaTags from '../Producto/utils/MetaTags';
import PaypalManager from '../paymentGateway/paypal/PaypalManager';


const DetalleProductoManager = ({ storeName, producto }) => {
    

    const title = 'Cómo Optimizar tu Sitio Web para SEO';
    const description = 'Aprende las mejores prácticas para mejorar el SEO de tu sitio web y aumentar el tráfico orgánico.';
    const image = 'https://tusitio.com/images/seo-optimizacion.jpg';
    const imageAlt = 'Imagen representativa de la optimización SEO';
    
    const author = 'Juan Pérez';
    
    
    

    console.log("producto_t",producto);
    const url = window.location.href;
    const keywords = 'SEO, optimización, tráfico orgánico, marketing digital';
    const tags = 'SEO,Marketing,Tráfico Orgánico';
    const publishedTime = '2023-10-01T12:00:00+00:00';
    const modifiedTime = '2023-10-05T15:30:00+00:00';
    const section = 'Marketing Digital';
    const appleTouchIcon = '/icons/apple-touch-icon.png';
    const msTileImage = '/icons/mstile-150x150.png';
    const msTileColor = '#2b5797';
    const googleSiteVerification = 'tu_codigo_de_verificacion';
    const facebookAppId = 'tu_facebook_app_id';
  return (

    <>

      <MetaTags
        title={producto.name}
        description={producto.description}
        image={producto.images[0]}
        imageAlt={producto.name}
        url={url}
        type="article"
        author={storeName}

        keywords={keywords}
        twitterSite="@tusitio"
        twitterCreator="@juanperez"
        publishedTime={publishedTime}
        modifiedTime={modifiedTime}
        section={section}
        tags={tags}
        appleTouchIcon={appleTouchIcon}
        msTileImage={msTileImage}
        msTileColor={msTileColor}
        googleSiteVerification={googleSiteVerification}
        facebookAppId={facebookAppId}
      />



      <DetalleProductoPreview 
        name={producto.name}
        price={producto.price}
        oldPrice={producto.oldPrice}
        productType={producto.productType}
        description={producto.description}
        vendor={producto.vendor}
        colors={producto.colors}
        sizes={producto.sizes}
        images={producto.images}
      />


    </>
  );
};

export default DetalleProductoManager;

{/* 
 <PaypalManager clientID={"ARDlXDm9-0nGfWpVpGCx6xdeMlzNLARduZ893Am82kIHb0-PQWPeKIDj0FJCVYk1mbSVafWCaBhk8S0h"} orderID={"3K867373638276611"}  />
*/}
     