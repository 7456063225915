import React, { useState, useEffect, useRef } from "react";
/*https://secure.safecharge.com/ppp/purchase.do*/
  function NuveiPaymentButton({
    actionURL = "https://ppp-test.safecharge.com/ppp/purchase.do",
    merchantId = "9187281402227145270",
    merchantSiteId = "256978",
    currency = "PEN",
    items = [
      { item_name: "Test Product 1", item_number:"1", item_quantity: "1", item_amount: "20.00",  },
      { item_name: "Test Product 2", item_number:"1", item_quantity: "1", item_amount: "30.00",  }
    ],
    numberofitems = "2",
    encoding = "utf-8",
    timeStamp = "2018-05-15.02:35:21",
    version = "4.0.0",
    userTokenId = "ran100418_scobd@mailinator.com",
    userToken = "auto",
    total_amount = "50.00",
    //item_name_1 = "Test Product",
    //item_number_1 = "1",
    //item_quantity_1 = "1",
    //item_amount_1 = "50.00",
    notifyUrl = "https://sandbox.nuvei.com/lib/demo_process_request/response.php",
    themeId = "178113",
    //successURL = "https://mispacecode.com/",
    //errorURL = "https://tusitio.com/payment-error",
    buttonLabel = "Pagar con Nuvei",
    style = {}
  }) {
    const formRef = useRef(null);
    const [checksum, setChecksum] = useState(null);
    console.log('checksum',checksum)

    /*useEffect(() => {
        const params = {
        merchant_id: merchantId,
        merchant_site_id: merchantSiteId,
        total_amount: total_amount,
        currency: currency,
        user_token_id: userTokenId,
        time_stamp: timeStamp,
        version: version,
        notify_url: notifyUrl
        };

        fetch('https://us-central1-oneclick-market.cloudfunctions.net/generatechecksum', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
        })
        .then(response => response.json())
        .then(data => {
        setChecksum(data.checksum);
        console.log('data',data);
        alert('ejecutar');
        })
        .catch(err => {
        console.error("Error obteniendo checksum:", err);
        });
    }, []);

  const handlePayment = () => {
    if (!checksum) {
      alert("Checksum no disponible aún. Por favor espera unos segundos e intenta de nuevo.");
      return;
    }
    if (formRef.current) {
      formRef.current.submit();
    }
  };*/

  const handlePayment = () => {
    if (checksum) {
      formRef.current.submit();
      return;
    }

    const params = {
      currency: currency,
      items: items,
      numberofitems:numberofitems,
      encoding:encoding,
      merchant_id: merchantId,
      merchant_site_id: merchantSiteId,
      time_stamp: timeStamp,
      version: version,
      user_token_id: userTokenId,
      userToken:userToken,
      total_amount: total_amount,
      notify_url: notifyUrl,
      themeId:themeId,
    };

    fetch('https://us-central1-oneclick-market.cloudfunctions.net/generatechecksum', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(data => {
      setChecksum(data.checksum);
      console.log('data', data);
      alert('ejecutar');
      if (formRef.current) {
        formRef.current.submit();
      }
    })
    .catch(err => {
      console.error("Error obteniendo checksum:", err);
    });
  };

  return (
    <div>
      <form ref={formRef} method="GET" action={actionURL} style={{ display: "none" }}>
      
        <input type="hidden" name="currency" value={currency} />
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <input type="hidden" name={`item_name_${index + 1}`} value={item.item_name} />
            <input type="hidden" name={`item_amount_${index + 1}`} value={item.item_amount} />
            <input type="hidden" name={`item_quantity_${index + 1}`} value={item.item_quantity} />
          </React.Fragment>
        ))}
        <input type="hidden" name="encoding" value={encoding} />
        <input type="hidden" name="time_stamp" value={timeStamp} />
        <input type="hidden" name="version" value={version} />
        <input type="hidden" name="user_token_id" value={userTokenId} />
        <input type="hidden" name="user_token" value={userToken} />
        <input type="hidden" name="total_amount" value={total_amount} />
        <input type="hidden" name="notify_url" value={notifyUrl} />
        <input type="hidden" name="theme_id" value={themeId} />
        <input type="hidden" name="checksum" value={checksum} />
      </form>

      <button style={style} onClick={handlePayment}>
        {buttonLabel}
      </button>
    </div>
  );
}

export default NuveiPaymentButton;