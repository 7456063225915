// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import MarketTema1 from './pages/MarketTema1';
import Login from './pages/Login';
import Producto from './pages/Producto';
import ProductoShare from './pages/ProductoShare';
import Tiendas from './pages/Tiendas';
import BlogDetail from './components/Blog/components/BlogDetail'
import Blog from './pages/Blog'
import PoliticaPrivacidad from './pages/PoliticasPrivacidad'
//import PoliticaPrivacidad from './pages/PoliticaSolid'
import TerminosServicio from './components/TerminosServicio/TerminosServicio'
import CallBackTiktok from './components/CallBackTiktok/CallBackTiktok'
import useStoreName from './hooks/useStoreName'; 



function App() {
  
  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const storeName = window.location.hostname.replace('www.', '').trim();
  
  const [productos, setProductos] = useState([]);

  return (

   
      <div className="App overflow-x-hidden" >

        <Routes>
          <Route path="/"  element={ storeName==='localhost' || storeName===urlReplace ? <Login /> : <MarketTema1 />}  />

          <Route 
            path="/:tienda/administrar" 
            element={<MarketTema1/>} 
          />
          <Route 
            path="/:tienda/administrar/producto/:id/:nombre" 
            element={<Producto productos={productos} />} 
          />
          <Route 
            path="/:tienda/administrar/producto-share/:id/:nombre" 
            element={<ProductoShare/>} 
          />
          <Route 
            path="/producto-share/:id/:nombre" 
            element={<ProductoShare/>} 
          />
          <Route 
            path="/producto/:id/:nombre" 
            element={<Producto />} 
          />
          
          <Route path="/admi_tienda" element={<Tiendas />} />
          <Route path="/:tienda/administrar/blog/:id/:nombre" element={<Blog/>} />
          <Route path="/blog/:id/:nombre" element={<Blog/>} />
          <Route path="/:tienda/administrar/politicasPrivacidad" element={<PoliticaPrivacidad/>} />
          <Route path="/politicasPrivacidad" element={<PoliticaPrivacidad/>} />
          <Route path="/terminosServicio" element={<TerminosServicio/>} />
          
          <Route path="/auth/callback/" element={<CallBackTiktok/>} />

        </Routes>
      </div>
  );
}

export default App;
