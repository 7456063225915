import React from "react";

const Ui3 = ({id,title,description,button,buttonColor,url1,reportUrl,button2,buttonColor2,url2,fontColor1,fontColor2}) => {
    
  return (

    <section className="text-gray-600  body-font w-[95%] md:w-full mx-auto mb-4 ">
      <div className="container  flex py-4  md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-full mb-10 md:mb-0 m-auto "> 
            <iframe
                title="Power BI Report"
                src={reportUrl}
                width="100%"
                className="h-[200px] md:h-[400px] md:w-[50%] w-full m-auto"
                style={{ border: "none" }}
                allowFullScreen
                />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-semibold text-gray-900">
                {title}
            </h1>
            <p className="mb-8 leading-relaxed text-left max-h-[120px] text-sm md:text-base text-justify">
                {description}
            </p>
            <div className="flex justify-center gap-2">
           
            <button className="inline-flex  bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
               style={{ backgroundColor: buttonColor, color: fontColor1 }}
                onClick={() => window.location.href = url1}
                >
              {button}
            </button>

            <button
              className="inline-flex  bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              style={{ backgroundColor: buttonColor2,color: fontColor2  }}
              onClick={() => window.location.href = url2}
            >
              {button2}
            </button>

            </div>
        </div>
      </div>
    </section>

  );
};

export default Ui3;
