import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import decompressReporte from "./utils/decompressReporte";
import compressReporte from "./utils/compressReporte";
import ReporteForm from "./componentes/ReporteForm";
import CardReporte from "./componentes/ui/CardReporte";

const PowerBIManager = ({ storeName, isAdminRoute, compressedReportes }) => {
  const [reportes, setReportes] = useState([]);
  const [tituloSeccion, setTituloSeccion] = useState("");
  const [diseñoSeleccionado, setDiseñoSeleccionado] = useState("ui1");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("reportes",reportes);

  
  useEffect(() => {
    try {
      const decompressedReportes = decompressReporte(compressedReportes || "");
      setTituloSeccion(tituloSeccion || ""); // Título por defecto
      setDiseñoSeleccionado(diseñoSeleccionado); // Diseño por defecto
      setReportes(Array.isArray(decompressedReportes) ? decompressedReportes : []);
    } catch (err) {
      console.error("Error al procesar los datos:", err);
      setError("Error al procesar los reportes.");
    } finally {
      setLoading(false);
    }
  }, [compressedReportes, tituloSeccion, diseñoSeleccionado]);

  const handleSaveConfig = async (newTitulo, newDiseño, newReportes) => {
    try {
      const dataToSave = {
        tituloSeccion: newTitulo,
        diseñoSeleccionado: newDiseño,
        reportes: compressReporte(newReportes),
      };
      await setDoc(doc(db, `${storeName}/config`), dataToSave, { merge: true });
      setTituloSeccion(newTitulo);
      setDiseñoSeleccionado(newDiseño);
      setReportes(newReportes);
    } catch (err) {
      console.error("Error al guardar la configuración:", err);
    }
  };

  const handleDeleteReporte = async (index) => {
    try {
      const updatedReportes = reportes.filter((_, i) => i !== index);
      await setDoc(
        doc(db, `${storeName}/config`),
        { reportes: compressReporte(updatedReportes) },
        { merge: true }
      );
      setReportes(updatedReportes);
    } catch (err) {
      console.error("Error al eliminar el reporte:", err);
    }
  };

  return (

    <div className="mb-2">
      <div className="flex justify-center items-center">
        <h1
          className="text-2xl font-semibold my-5"
          style={{ color: "#255ce0", fontSize: "35px", fontWeight: "800" }}
        >
          {tituloSeccion || "Reportes"}
        </h1>
      

      {loading && <p>Cargando...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {!loading && (
        <ReporteForm
          storeName={storeName}
          initialTituloSeccion={tituloSeccion}
          initialDiseñoSeleccionado={diseñoSeleccionado}
          initialReportes={reportes}
          onSave={handleSaveConfig}
        />
      )}
      </div>

      {reportes.map((reporte, index) => (
        <CardReporte
          key={index}
          designVariant={diseñoSeleccionado}
          id={reporte.id || index}
          title={reporte.titulo}
          reportUrl={reporte.url}
          description={reporte.descripcion || "Sin descripción disponible."}
          button={reporte.button1Content || "Ver mas"}
          buttonColor={reporte.button1Color || "#255ce0"}
          url1={reporte.button1Url || ""}
          button2={reporte.button2Content || "Ver mas"}
          buttonColor2={reporte.button2Color || "#255ce0"}
          url2={reporte.button2Url || ""}
          onDelete={() => handleDeleteReporte(index)}
          fontColor1={reporte.button1TextColor || "#ffffff"}
          fontColor2={reporte.button2TextColor || "#ffffff"}
        />
      ))}
    </div>
  );
};

export default PowerBIManager;
