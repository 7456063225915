import React from 'react';
//import { db, collection, addDoc, getDocs } from '../../firebaseConfig';
import PoliticaPrivacidadPreview from './components/desings/PoliticaPrivacidadPreview';
//import PoliticaPrivacidadDesing1 from './components/desings/PoliticaPrivacidadDesing1';
//import PoliticaPrivacidadModal from './components/PoliticaPrivacidadModal';
//import LZString from 'lz-string';

const PoliticaPrivacidadManager = ({ storeName, isAdminRoute }) => {
  //const [politicas, setPoliticas] = useState([]); // Manejar políticas en lugar de posts
  //const [isModalOpen, setIsModalOpen] = useState(false); // Estado para abrir/cerrar el modal
  //const [newPoliticaContent, setNewPoliticaContent] = useState(''); // Contenido del editor

  // Fetch políticas desde Firebase
  /*useEffect(() => {
    const fetchPoliticas = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, `${storeName}/politicas/entry`));
        const politicasFromFirestore = querySnapshot.docs.map((doc) => {
          const compressedContent = doc.data().content;

          // Descomprimir contenido en Base64
          const decompressedContent = LZString.decompressFromBase64(compressedContent);

          return {
            id: doc.id,
            content: decompressedContent, // Usar el contenido descomprimido
          };
        });

        setPoliticas(politicasFromFirestore);
      } catch (error) {
        console.error('Error al obtener las políticas de Firestore:', error.message);
      }
    };

    fetchPoliticas();
  }, [storeName]);*/

  // Función para guardar nueva política en Firebase
  /*const handleSavePolitica = async () => {
    if (!newPoliticaContent.trim()) {
      alert('El contenido no puede estar vacío.');
      return;
    }

    try {
      const compressedContent = LZString.compressToBase64(newPoliticaContent);

      const newPolitica = {
        content: compressedContent, // Guardar el contenido comprimido
      };

      const docRef = await addDoc(collection(db, `${storeName}/politicas/entry`), newPolitica);

      setPoliticas((prevPoliticas) => [
        { id: docRef.id, content: newPoliticaContent },
        ...prevPoliticas,
      ]);

      setIsModalOpen(false); // Cerrar el modal
      setNewPoliticaContent(''); // Reiniciar el contenido
    } catch (error) {
      console.error('Error al guardar la política en Firestore:', error.message);
    }
  };*/

  return (
    <>
      <PoliticaPrivacidadPreview storeName={storeName} isAdminRoute={isAdminRoute}/>
      {/* <PoliticaPrivacidadDesing1
        politicas={politicas}
        onAddButtonClick={() => setIsModalOpen(true)} // Abrir modal al hacer clic
        isAdminRoute={isAdminRoute}
      />

      <PoliticaPrivacidadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Cerrar modal
        onSave={handleSavePolitica} // Guardar política
        content={newPoliticaContent}
        setContent={setNewPoliticaContent} // Actualizar contenido del editor
      />*/}
    </>
  );
};

export default PoliticaPrivacidadManager;