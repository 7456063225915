import React from 'react';

const CardServicio2 = ({ icono, titulo, descripcion, color, textoEnlace, href, hasLink }) => {
    return (
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div className="p-4 w-full flex flex-col text-center items-center">
                <div className="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-400 mb-3 flex-shrink-0" style={{
                    backgroundColor: color,
                }}>
                    <img
                        src={icono}
                        alt={`${titulo} icon`}
                        className="object-contain w-10 h-10"
                    />
                </div>
                <div className="flex-grow mb-9 md:mb-0">
                    <h2 className="text-lg title-font font-semibold mb-2">
                        {titulo}
                    </h2>
                    <p className="leading-relaxed text-base text-center text-last-center lg:text-left">{descripcion}</p>
                    {hasLink && (
                        <a href={href} className="mt-1 text-indigo-400 inline-flex items-center hover:underline">
                            {textoEnlace}
                            <svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="w-4 h-4 ml-2"
                                viewBox="0 0 24 24"
                            >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                            </svg>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardServicio2;