import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import compressNavbar from "../utils/compressNavbar";

const NavbarForm = ({ storeName, open, onClose, onSave, currentNavbar }) => {
  const [items, setItems] = useState([]);
  const [headerType, setHeaderType] = useState("diseño1");
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState("#ffffff");

  console.log("currentNavbar",currentNavbar);

  useEffect(() => {
    if (currentNavbar) {
      setItems(currentNavbar.items || []);
      setHeaderType(currentNavbar.headerType || "diseño1");
      setHeaderBackgroundColor(currentNavbar.headerBackgroundColor || "#ffffff");
    }
  }, [currentNavbar]);

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([...items, { label: "", type: "link", href: "", buttonColor: "", textColor: "" }]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSave = () => {
    const compressedData = compressNavbar({
      headerType,
      headerBackgroundColor,
      items,
    });
    onSave(compressedData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Editar Navbar</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          {/* Campo para seleccionar el tipo de header */}
          <FormControl fullWidth>
            <InputLabel>Diseño de Header</InputLabel>
            <Select value={headerType} onChange={(e) => setHeaderType(e.target.value)}>
              <MenuItem value="diseño1">Diseño 1</MenuItem>
              <MenuItem value="diseño2">Diseño 2</MenuItem>
              <MenuItem value="diseño3">Diseño 3</MenuItem>
              <MenuItem value="diseño4">Diseño 4</MenuItem>
            </Select>
          </FormControl>

          {/* Campo para seleccionar el color de fondo del header */}
          <TextField
            label="Color de fondo del header"
            type="color"
            value={headerBackgroundColor}
            onChange={(e) => setHeaderBackgroundColor(e.target.value)}
            fullWidth
          />

          {/* Lista de ítems */}
          {items.map((item, index) => (
            <Stack key={index} spacing={2} alignItems="flex-start">
              {/* Primera fila: texto del ítem, URL y tipo */}
              <Stack direction="row" spacing={2} alignItems="center" width="100%">
                <TextField
                  label="Texto del ítem"
                  value={item.label}
                  onChange={(e) => handleItemChange(index, "label", e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Destino (URL)"
                  value={item.href}
                  onChange={(e) => handleItemChange(index, "href", e.target.value)}
                  fullWidth
                />
                <FormControl fullWidth>
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    value={item.type}
                    onChange={(e) => handleItemChange(index, "type", e.target.value)}
                  >
                    <MenuItem value="link">Link</MenuItem>
                    <MenuItem value="button">Button</MenuItem>
                  </Select>
                </FormControl>
                <IconButton onClick={() => handleRemoveItem(index)}>
                  <Delete />
                </IconButton>
              </Stack>

              {/* Segunda fila: campos adicionales para botones */}
              {item.type === "button" && (
                <Stack direction="row" spacing={2} alignItems="center" width="100%">
                  <TextField
                    label="Color de fondo del botón"
                    type="color"
                    value={item.buttonColor}
                    onChange={(e) => handleItemChange(index, "buttonColor", e.target.value)}
                    fullWidth
                  />
                  <TextField
                    label="Color del texto del botón"
                    type="color"
                    value={item.textColor}
                    onChange={(e) => handleItemChange(index, "textColor", e.target.value)}
                    fullWidth
                  />
                </Stack>
              )}
            </Stack>
          ))}

          <Button variant="outlined" onClick={handleAddItem} startIcon={<Add />}>
            Agregar ítem
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NavbarForm;
