import React from 'react';

const HEIGHTS = {
  image1: "540px",
  image2: "432px",
  image3: "432px",
  image4: "168px",
  image5: "168px",
  image6: "498px",
  image7: "168px",
};

const GaleriaDesing1 = ({ title, colorFondo, titleColor = "#000", images = [] }) => {
  const renderImage = ({ src, alt, hasLink, href }) => {
    if (!src) return null;
    if (hasLink && href) {
      return (
        <button onClick={() => window.open(href, "_blank", "noopener,noreferrer")} className="w-full h-full p-0 border-none bg-transparent">
          <img
            src={src}
            alt={alt}
            className="w-full h-full object-fill object-center"
          />
        </button>
      );
    }
    return (
      <img
        src={src}
        alt={alt}
        className="w-full h-full object-fill object-center"
      />
    );
  };

  return (

    <div className="rounded-t-[30px] px-3 lg:px-[100px] py-[41px] z-[2] relative" style={{ backgroundColor: colorFondo }}>
      
      <h2 className="text-[30px] lg:text-[40px] font-bold leading-8 mb-[45px] whitespace-normal break-normal" style={{ color: titleColor }}>
        {title}
      </h2>


      <div
        className="flex flex-col lg:grid gap-4 container mx-auto"
        style={{
          gridTemplateColumns: "repeat(5, 1fr)",
          gridTemplateAreas: `
            "image1 image1 image1  image1 image4"
            "image1 image1 image1  image1 image5"
            "image1 image1 image1  image1 image6"
            "image2 image3 image3  image3 image6"
            "image2 image3 image3  image3 image7"
          `,
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className={`bg-white overflow-hidden shadow-lg `}
            style={{
              gridArea: image.gridArea,
              borderRadius: image.rounded,
              maxHeight: HEIGHTS[image.gridArea],
            }}
          >
            {renderImage(image)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GaleriaDesing1;