import React from 'react';

const Ui2 = ({ icon, title, description,porcentaje,color,angle,colorStart,colorEnd}) => {
    return (

    <div className="flex justify-center  flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
        <div className="p-4 flex flex-col text-center items-center">
            
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-400  flex-shrink-0"
              style={{
                background: `linear-gradient(${angle}deg, ${colorStart}, ${colorEnd})`}}  >

               <p className='font-semibold text-white text-[24px] '
                style={{
                    color: `${color}`}}>
                    {porcentaje}%
                </p>
            </div>

            <div className="flex-grow">
                <h2 className="text-white text-lg title-font font-medium mb-3">
                    {title}
                </h2>
                <p className="leading-relaxed text-base text-center">{description}</p>
                <a className="mt-1 text-indigo-400 inline-flex items-center">
                    Learn More
                    <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                </a>
            </div>
            
        </div>
    </div>
    );
};

export default Ui2;