import React, { useState, useEffect } from 'react';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import LZString from 'lz-string';
import { db } from '../../../firebaseConfig';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'], // Botones de imagen y video
      ['code'],
    ],
    handlers: {
      code: function () {
        const htmlCode = prompt('Ingresa el código HTML del iframe:');
        if (htmlCode) {
          const quill = this.quill;
          const range = quill.getSelection();
          quill.clipboard.dangerouslyPasteHTML(range.index, htmlCode); // Inserta el HTML sin escapar
        }
      },
      image: function () {
        const url = prompt('Ingresa la URL de la imagen:');
        if (url) {
          const quill = this.quill;
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', url); // Inserta la imagen como URL
        }
      },
      video: function () {
        const url = prompt('Ingresa la URL del video (YouTube, Vimeo, etc.):');
        if (url) {
          const quill = this.quill;
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'video', url); // Inserta el video como URL
        }
      },
    },
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'link',
  'image',
  'video',
];

const PoliticaPrivacidadModal = ({
  isOpen,
  onClose,
  politica,
  storeName,
  setPoliticas,
}) => {
  const [content, setContent] = useState('');
  const [isHtmlMode, setIsHtmlMode] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [sanitizeError, setSanitizeError] = useState('');

  useEffect(() => {
    if (politica) {
      setContent(politica.content || '');
      setHtmlContent(politica.content || '');
    } else {
      setContent('');
      setHtmlContent('');
    }
  }, [politica]);

  const handleSave = async () => {
    if (!content.trim()) {
      alert('El contenido no puede estar vacío.');
      return;
    }

    try {
      const compressedContent = LZString.compressToBase64(content);

      if (politica) {
        // Editar política existente
        const docRef = doc(db, `${storeName}/politicas/entry`, politica.id);
        await updateDoc(docRef, { content: compressedContent });
        setPoliticas((prev) =>
          prev.map((p) => (p.id === politica.id ? { ...p, content } : p))
        );
      } else {
        // Crear nueva política
        const docRef = await addDoc(collection(db, `${storeName}/politicas/entry`), {
          content: compressedContent,
        });
        setPoliticas((prev) => [
          ...prev,
          { id: docRef.id, content },
        ]);
      }

      onClose(); // Cerrar modal
    } catch (error) {
      console.error('Error al guardar la política:', error.message);
    }
  };

  const handleHtmlChange = (e) => {
    const newHtml = e.target.value;
    // Sanitizar el contenido HTML
    const sanitizedHtml = DOMPurify.sanitize(newHtml, {
      ALLOWED_TAGS: [
        'b',
        'i',
        'em',
        'strong',
        'a',
        'p',
        'ul',
        'ol',
        'li',
        'br',
        'span',
        'div',
        'img',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'blockquote',
        'pre',
        'code',
        'button',
        'textarea',
        'form',
        'input',
        'select',
        'option',
      ],
      ALLOWED_ATTR: [
        'href',
        'title',
        'src',
        'alt',
        'style',
        'type',
        'value',
        'name',
        'placeholder',
        'disabled',
        'readonly',
        'checked',
        'required',
      ],
    });

    if (newHtml !== sanitizedHtml) {
      setSanitizeError('Se han eliminado scripts o contenido malicioso.');
    } else {
      setSanitizeError('');
    }

    setHtmlContent(sanitizedHtml);
    setContent(sanitizedHtml);
  };

  const toggleHtmlMode = () => {
    if (isHtmlMode) {
      setContent(htmlContent);
    }
    setIsHtmlMode(!isHtmlMode);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {politica ? 'Editar Política de Privacidad' : 'Agregar Política de Privacidad'}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">Modo:</Typography>
          <Button variant="outlined" onClick={toggleHtmlMode}>
            {isHtmlMode ? 'Modo Visual' : 'Editar HTML'}
          </Button>
        </Box>
        <Divider sx={{ mb: 2 }} />
        {isHtmlMode ? (
          <TextField
            value={htmlContent}
            multiline
            rows={10}
            fullWidth
            onChange={handleHtmlChange}
            label="Edición HTML"
            variant="outlined"
            helperText={
              sanitizeError
                ? sanitizeError
                : 'Edite el HTML directamente. Se eliminarán scripts automáticamente.'
            }
            error={!!sanitizeError}
          />
        ) : (
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            modules={modules}
            formats={formats}
            style={{ height: '250px' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!content.trim()} // Deshabilitar si está vacío
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PoliticaPrivacidadModal;