import React from 'react';
import { Button } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const FooterAddButton = ({ user, onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<DesignServicesIcon />}
      onClick={onClick}
      className='z-10'
      sx={{
        position: 'absolute',
        top: '2rem',
        right: '-5rem', 
        transition: 'right 0.3s ease', 
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 'none',
        background: 'linear-gradient(90deg, #a000b8, #5724ff 20%, #003efa)', 
        color: 'white', 
        '&:hover': {
          right: '0rem', 
          background: 'linear-gradient(90deg, #a000b8, #5724ff 50%, #003efa)', 
        },
      }}
    >
      Footer
    </Button>
  );
};

export default FooterAddButton;
