import React, { useState } from 'react';


const UiDefault = ({ id, titulo, descripcion, color, porcentaje,colorStart,colorEnd,angle}) => {
  




  return (


    <div
      className="rounded-lg flex items-start gap-2 flex-col shadow-efecto gap-2 transition-transform transition-shadow duration-300 hover:shadow-card hover:-translate-y-2"
      style={{
        padding: '40px',
        borderRadius: '20px',
        background: `linear-gradient(${angle}deg, ${colorStart}, ${colorEnd})`,
      }}
    >
      <div className="flex justify-between w-full items-center">
        <div>
          <p style={{ color: color, fontSize: '50px', fontWeight: '700' }} className="">
            {porcentaje}aa%
          </p>
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        <p className="text-left font-bold text-xl text-[20px] text-black">{titulo}</p>
        <p className="text-left font-normal text-base text-black max-h-[100px] min-h-[100px] line-clamp-4">{descripcion}</p>
      </div>

      
    </div>
  );
};

export default UiDefault;
