import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Grid,
  Radio,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete } from "@mui/icons-material";
import LZString from "lz-string";
import GradientPicker from "./GradientPicker";
//import { ButtonWithModal } from 'input-text-kit';

const BeneficiosForm = ({ open, onClose, initialBeneficios, onSave }) => {
  const [beneficios, setBeneficios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [tituloSeccion, setTituloSeccion] = useState("");
  
  const [colorFondo, setColorFondo] = useState("");
  const [colorTexto, setColorTexto] = useState("");
  const [colorTitulo, setColorTitulo] = useState("");


  
  const disenos = [
    {
      value: "ui1",
      label: "Diseño 1",
      image: "https://via.placeholder.com/80x40/ff0000/ffffff?text=1",
    },
    {
      value: "ui2",
      label: "Diseño 2",
      image: "https://via.placeholder.com/80x40/00ff00/000000?text=2",
    },
    {
      value: "ui3",
      label: "Diseño 3",
      image: "https://via.placeholder.com/80x40/0000ff/ffffff?text=3",
    },
    {
      value: "ui4",
      label: "Diseño 4",
      image: "https://via.placeholder.com/80x40/0000ff/ffffff?text=4",
    },
  ];
  
  const [disenoSeleccionado, setDisenoSeleccionado] = useState(disenos[0].value);
  const isArray = Array.isArray(initialBeneficios);
  const arrayBeneficios = isArray 
    ? initialBeneficios || []
    : initialBeneficios?.beneficios || [];

  console.log("initialBeneficios", initialBeneficios);

    useEffect(() => {
  if (initialBeneficios) {
      setBeneficios(arrayBeneficios);
      setLoading(false);
    } else {
      setBeneficios([]);
      setLoading(false);
    }
      }, [initialBeneficios]);


    const handleChange = (index, e) => {
    const { name, value } = e.target;
    setBeneficios((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const handleAddBeneficio = () => {
    setBeneficios((prev) => [
      ...prev,
      {
        id: Date.now(), // O algún generador de IDs
        titulo: "",
        descripcion: "",
        porcentaje: 20,
        colorStart: "#d9ddf1",
        colorEnd: "#ffffff",
        angle: "180deg",
      },
    ]);
   
  };

  const handleDeleteBeneficio = (index) => {
    setBeneficios((prev) => prev.filter((_, i) => i !== index));
    if (expandedIndex === index) {
      setExpandedIndex(-1); // Cierra el panel si era el que estaba abierto
    } else if (expandedIndex > index) {
      setExpandedIndex((prev) => prev - 1);
    }
  };

  const handleSaveAll = () => {
    const dataToCompress = {
      tituloSeccion,
      disenoSeleccionado,
      beneficios,
      ...(disenoSeleccionado === "ui4" && { 
        colorFondo, 
        colorTexto, 
        colorTitulo 
      }), // Solo incluye colores si el diseño es "ui4"
    };
  
    const compressedData = LZString.compressToBase64(
      JSON.stringify(dataToCompress)
    );
    onSave(compressedData);
  };
  

  /**
   * Maneja la expansión de cada Accordion.
   * Si el usuario hace clic en un Accordion que ya está abierto, lo cierra (pone -1).
   * Si hace clic en otro Accordion, cierra el anterior y abre el nuevo.
   */

  const handleAccordionChange = (panelIndex) => {
    setExpandedIndex((prevIndex) => (prevIndex === panelIndex ? -1 : panelIndex));
  };

  const handleUpdateGradient = (index, startColor, endColor, angle) => {
    setBeneficios((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        colorStart: startColor,
        colorEnd: endColor,
        angle: angle.toString(), // o manténlo como número, si prefieres
      };
      return updated;
    });
  };

  return (
    
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 800 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Editar Beneficios
        </Typography>

        {loading ? (
          // Spinner de carga
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
           
           
            <Typography variant="h6" sx={{ mb: 1 }}>
              Configuración de Sección
            </Typography>

            {/* Título de la Sección */}
            <TextField
              fullWidth
              label="Título de la Sección"
              value={tituloSeccion}
              onChange={(e) => setTituloSeccion(e.target.value)}
              margin="normal"
            />

<Grid container spacing={2}>
  <Grid item xs={4}>
    <TextField
      fullWidth
      label="Color de fondo"
      name="colorFondo"
      type="color"
      value={colorFondo}
      onChange={(e) => setColorFondo(e.target.value)}
      disabled={disenoSeleccionado !== "ui4"} // Deshabilitado si no es "ui4"
    />
  </Grid>
  <Grid item xs={4}>
    <TextField
      fullWidth
      label="Color del título"
      name="colorTitulo"
      type="color"
      value={colorTitulo}
      onChange={(e) => setColorTitulo(e.target.value)}
      disabled={disenoSeleccionado !== "ui4"} // Deshabilitado si no es "ui4"
    />
  </Grid>
  <Grid item xs={4}>
    <TextField
      fullWidth
      label="Color de la descripción"
      name="colorDescripcion"
      type="color"
      value={colorTexto}
      onChange={(e) => setColorTexto(e.target.value)}
      disabled={disenoSeleccionado !== "ui4"} // Deshabilitado si no es "ui4"
    />
  </Grid>
</Grid>

          

            {/* Selección de Diseño */}
            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
              Elige un Diseño
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              {disenos.map((diseno) => (
                <Box
                  key={diseno.value}
                  onClick={() => setDisenoSeleccionado(diseno.value)}
                  sx={{
                    border:
                      disenoSeleccionado === diseno.value
                        ? "2px solid blue"
                        : "1px solid #ccc",
                    borderRadius: 2,
                    p: 1,
                    cursor: "pointer",
                    textAlign: "center",
                    // Opcional: agregar efecto de sombra al seleccionar
                    boxShadow: disenoSeleccionado === diseno.value ? "0 0 10px rgba(0, 0, 255, 0.5)" : "none",
                    transition: "border 0.3s, box-shadow 0.3s",
                  }}
                >
                  <img
                    src={diseno.image}
                    alt={diseno.label}
                    style={{ display: "block", margin: "0 auto", maxWidth: "100%", height: "auto" }}
                  />
                </Box>
              ))}
            </Stack>


           
            <Typography variant="h6" sx={{ mt: 3 }}>
              Lista de Beneficios
            </Typography>

            {beneficios.length === 0 && (
              <Typography variant="body2" sx={{ mb: 2, color: "gray" }}>
                No hay beneficios disponibles.
              </Typography>
            )}

            {beneficios.map((beneficio, index) => (
              <Accordion
  key={beneficio.id || index} // Aquí `beneficio` debe venir del mapeo
  expanded={expandedIndex === index}
  onChange={() => handleAccordionChange(index)}
>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {beneficio.titulo || "Nuevo Beneficio"}
                    </Typography>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); 
                        handleDeleteBeneficio(index);
                      }}
                      aria-label="delete"
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        {/* Título */}
                        <TextField
                          fullWidth
                          label="Título"
                          name="titulo"
                          value={beneficio.titulo}
                          onChange={(e) => handleChange(index, e)}
                        />

                        {/* Descripción */}
                        <TextField
                          fullWidth
                          label="Descripción"
                          name="descripcion"
                          multiline
                          rows={4}
                          value={beneficio.descripcion}
                          onChange={(e) => handleChange(index, e)}
                        />

                        {/* Porcentaje */}
                        <TextField
                          fullWidth
                          margin="normal"
                          label="Porcentaje"
                          name="porcentaje"
                          value={beneficio.porcentaje}
                          onChange={(e) => handleChange(index, e)}
                          type="number"
                          inputProps={{ min: 0, max: 100 }}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <GradientPicker
                        initialStartColor={beneficio.colorStart}
                        initialEndColor={beneficio.colorEnd}
                        initialAngle={parseInt(beneficio.angle, 10)}
                        onChange={({ startColor, endColor, angle }) => {
                          handleUpdateGradient(index, startColor, endColor, angle);
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
            
          </>
        )}

        
        {!loading && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddBeneficio}
            sx={{ mt: 2 }}
          >
            Agregar Beneficio
          </Button>
        )}

       
        {!loading && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleSaveAll}
          >
            Guardar Todo
          </Button>
        )}


{/*<ButtonWithModal />*/}

      </Box>
    </Modal>
    
  );
};

export default BeneficiosForm;


