import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebaseConfig';
import { Box } from '@mui/material';
import { saveToFirestore } from '../../utils/firebaseUtils';
import decompressGaleria from './utils/decompressGaleria';
import GaleriaEditButton from './components/GaleriaEditButton';
import GaleriaDesing from './components/desings/GaleriaDesing';
import GaleriaModal from './components/GaleriModal';

const GaleriaManager = ({storeName, isAdminRoute, compressedGaleria}) => {

    const [user, setUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [galeriaArray, setGaleriaArray] = useState([]);
    console.log('galeriaArray', galeriaArray);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
          setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchGaleria = async () => {
          if (!compressedGaleria) {
            console.error('No se proporcionaron datos comprimidos para la galeria.');
            setError('Datos de servicio no disponibles.');
            setLoading(false);
            return;
          }
          try {
            const decompressedData = decompressGaleria(compressedGaleria);
            if (!decompressedData) {
              throw new Error('Descompresión fallida.');
            }
            setGaleriaArray(decompressedData);
          } catch (err) {
            console.error('Error al procesar la galeria:', err);
            setError('Error al cargar la galeria.');
          } finally {
            setLoading(false);
          }
        };
        fetchGaleria();
    }, [storeName]);

    const handleSaveGaleria = async (compressedGaleriaData) => {
        try {
            const decompressedData = decompressGaleria(compressedGaleriaData);
            setGaleriaArray(decompressedData);
            await saveToFirestore(storeName, 'config', { galeria: compressedGaleriaData });
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error al guardar imagenes de galeria:', error);
        }
    };

    const handleTempUpdate = (updatedData) => {
      // Este updatedData es el mismo shape que "decompressedData" o que se llama "dataToCompress"
      setGaleriaArray(updatedData);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <GaleriaDesing user={user} galeriaArray={galeriaArray}/>

            {user && (
              <GaleriaEditButton onClick={() => setIsModalOpen(true)}/>
            )}

            <GaleriaModal 
              open={isModalOpen} 
              onClose={() => setIsModalOpen(false)}
              onSave={handleSaveGaleria}
              storeName={storeName}
              initialGaleria={galeriaArray}
              onTempUpdate={handleTempUpdate}
            />
        </Box>
    );
}

export default GaleriaManager;