import React from 'react';
import { Box, Grid } from '@mui/material';
import SkeletonLoader from '../../../Beneficios/components/SkeletonLoader';
import GaleriaDesing1 from './GaleriaDesing1';

const GaleriaDesing = ({ galeriaArray, width = 300, height = 200 }) => {
  if (!galeriaArray) {
    return (
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <SkeletonLoader width={width} height={height} />
          </Grid>
        ))}
      </Grid>
    );
  }

  const { tituloSeccion, tituloColor, fondoColor, galeria } = galeriaArray;

  if (!galeria) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'darkgrey',
          fontSize: '14px',
          textAlign: 'center',
          width: '100%',
          height: '100%',
          padding: '5%',
        }}
      >
        No hay imágenes para mostrar
      </Box>
    );
  }

  const images = [
    { src: galeria.image1, alt: "imagen1", hasLink: galeria.image1HasLink, href: galeria.image1Href, gridArea: "image1", rounded: galeria.rounded },
    { src: galeria.image2, alt: "imagen2", hasLink: galeria.image2HasLink, href: galeria.image2Href, gridArea: "image2", rounded: galeria.rounded },
    { src: galeria.image3, alt: "imagen3", hasLink: galeria.image3HasLink, href: galeria.image3Href, gridArea: "image3", rounded: galeria.rounded },
    { src: galeria.image4, alt: "imagen4", hasLink: galeria.image4HasLink, href: galeria.image4Href, gridArea: "image4", rounded: galeria.rounded },
    { src: galeria.image5, alt: "imagen5", hasLink: galeria.image5HasLink, href: galeria.image5Href, gridArea: "image5", rounded: galeria.rounded },
    { src: galeria.image6, alt: "imagen6", hasLink: galeria.image6HasLink, href: galeria.image6Href, gridArea: "image6", rounded: galeria.rounded },
    { src: galeria.image7, alt: "imagen7", hasLink: galeria.image7HasLink, href: galeria.image7Href, gridArea: "image7", rounded: galeria.rounded },
  ];

  console.log('images', tituloSeccion)

  return (
    <Box >
      <GaleriaDesing1
        title={tituloSeccion}
        colorFondo={fondoColor}
        titleColor={tituloColor}
        images={images}
      />
    </Box>
  );
};

export default GaleriaDesing;
