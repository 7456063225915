import React from 'react';
import {  Button } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const BannerEditButton = ({ onClick }) => {
  return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<DesignServicesIcon />}
        onClick={onClick}
        sx={{
          position: 'absolute',
          top: '1rem',
          right: '-4.9rem',                // Posición inicial fuera de vista
          transition: 'right 0.3s ease',// Transición suave al modificar la propiedad "right"
          borderTopRightRadius: 0,    
          background: 'linear-gradient(90deg, #a000b8, #5724ff 20%, #003efa)', // Aplicar gradiente
          borderBottomRightRadius: 0, 
          borderRight: 'none',
          '&:hover': {
            right: '0rem',             // Nueva posición al hacer hover
            background: 'linear-gradient(90deg, #a000b8, #5724ff 50%, #003efa)', // Reaplica gradiente en hover
          },
        }}
      >
        BANNER
      </Button>
  );
};

export default BannerEditButton;

