import React from 'react';


const Ui4 = ({ id, titulo, descripcion,colorTexto }) => {
  
    return (  
    
        <div className='flex flex-col gap-[5px] md:mb-[20px] lg:mb-0 md:gap-3 items-start p-[20px] md:p-0'>
            
            <p className='font-bold text-left text-xl mb-0 leading-normal' style={{
                    color: `${colorTexto}`}} >{titulo}</p>

            <p className='font-normal text-left text-base md:max-w-[215px] leading-normal !text-sm	 md:!text-base	' style={{
                    color: `${colorTexto}`}}  >{descripcion}</p>

        </div>

   
  );
};

export default Ui4;
