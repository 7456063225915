import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Tooltip,
  Fab,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";

const BlogTiktokShare = ({ title, shortDescription, image, storeName }) => {
  
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    title: "",
    shortDescription: "",
    disableComment: false,
    privacyLevel: "",
    autoAddMusic: false,
    photoImages: "",
    postMode: "",
  });

  useEffect(() => {
    if (open) {
      setFormValues((prev) => ({
        ...prev,
        title: title || "",
        shortDescription: shortDescription || "",
        photoImages: image || "",
      }));
    }
  }, [open, title, shortDescription, image]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    const accessToken = localStorage.getItem(`${storeName}_tiktok_access_token`);
  
    if (!accessToken) {
      alert("No se encontró el token de acceso de TikTok. Por favor, inicia sesión.");
      setIsSubmitting(false);
      return;
    }
  
    const functionUrl = "https://us-central1-oneclick-market.cloudfunctions.net/sharetiktok";
  
    const payload = {
      post_info: {
        title: formValues.title ,
        description: formValues.shortDescription ,
        disable_comment: Boolean(formValues.disableComment),
        privacy_level: formValues.privacyLevel ,
        auto_add_music: Boolean(formValues.autoAddMusic),
      },
      source_info: {
        source: "PULL_FROM_URL",
        photo_cover_index: 0,
        photo_images: Array.isArray(formValues.photo_images)
        ? formValues.photo_images
        : [formValues.photo_images],
      },
      post_mode: formValues.postMode ,
      media_type: "PHOTO",
    };
  
    try {
      console.log("Payload enviado al backend:", JSON.stringify(payload, null, 2));
      const response = await axios.post(functionUrl, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.data.success) {
        alert("Publicación en TikTok realizada con éxito.");
        handleClose();
      } else {
        alert(`Error: ${response.data.error}`);
      }
    } catch (error) {
      console.error("Error al compartir en TikTok:", error);
      alert(`Error al compartir en TikTok: ${error.response?.data?.error || error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <>
      <Tooltip title="Compartir en TikTok" arrow>
        <Fab
          color="primary"
          aria-label="add"
          style={{
            backgroundColor: "white",
            boxShadow: 1,
            overflow: "hidden",
            width: "40px",
            height: "40px",
          }}
          onClick={handleOpen}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/tiktokLogo.png`}
            alt="TikTok Logo"
            style={{
              width: "30px",
              height: "35px",
            }}
          />
        </Fab>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" textAlign="center" mb={2}>
            Compartir en TikTok
          </Typography>

          <TextField
            label="Título"
            name="title"
            value={formValues.title}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            disabled={isSubmitting}
          />

          <TextField
            label="Descripción Corta"
            name="shortDescription"
            value={formValues.shortDescription}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            disabled={isSubmitting}
          />

          <FormControlLabel
            control={
              <Checkbox
                name="disableComment"
                checked={formValues.disableComment}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            }
            label="Deshabilitar comentarios"
          />

          <FormControl fullWidth margin="normal" disabled={isSubmitting}>
            <InputLabel id="privacyLevel-label">Nivel de Privacidad</InputLabel>
            <Select
              labelId="privacyLevel-label"
              name="privacyLevel"
              value={formValues.privacyLevel}
              onChange={handleChange}
            >
              <MenuItem value="PUBLIC_TO_EVERYONE">Público</MenuItem>
              <MenuItem value="SELF_ONLY">Privado</MenuItem>
              <MenuItem value="MUTUAL_FOLLOW_FRIENDS">Amigos</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                name="autoAddMusic"
                checked={formValues.autoAddMusic}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            }
            label="Agregar música automáticamente"
          />

          <TextField
            label="URL de Imagen"
            name="photoImages"
            value={formValues.photoImages}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
            disabled={isSubmitting}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="postMode-label">Modo de Publicación</InputLabel>
            <Select
              labelId="postMode-label"
              name="postMode"
              value={formValues.postMode}
              onChange={handleChange}
              disabled={isSubmitting}
            >
              <MenuItem value="DIRECT_POST">Publicar Ahora</MenuItem>
              <MenuItem value="MEDIA_UPLOAD">Programar</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Compartiendo..." : "Compartir"}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default BlogTiktokShare;































