import React from 'react';
import { useNavigate } from 'react-router-dom';

const PoliticaPrivacidadPreview = ({storeName, isAdminRoute}) => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        const targetRoute = isAdminRoute
        ? `/${storeName}/administrar/politicasPrivacidad`
        : '/politicasPrivacidad';
        navigate(targetRoute);
    };

    return (
        <div className="bg-[#f5f5f5]">
            <p>
                Conoce nuestras <button className="underline" onClick={handleRedirect}>Políticas de Privacidad</button>
            </p>
        </div>
    );
};

export default PoliticaPrivacidadPreview;