import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Tooltip,
  Fab,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { Delete } from "@mui/icons-material";

const ReporteForm = ({
  storeName,
  initialTituloSeccion,
  initialDiseñoSeleccionado,
  initialReportes,
  onSave,
}) => {
  const [open, setOpen] = useState(false);
  const [tituloSeccion, setTituloSeccion] = useState(initialTituloSeccion || "");
  const [diseñoSeleccionado, setDiseñoSeleccionado] = useState(initialDiseñoSeleccionado || "Ui1");
  const [reportes, setReportes] = useState(initialReportes || []);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [loading, setLoading] = useState(false);

  const [dataComplete, setDataComplete] = useState({
    tituloSeccion: initialTituloSeccion || "",
    diseñoSeleccionado: initialDiseñoSeleccionado || "Ui1",
    reportes: initialReportes || [],
  });

  useEffect(() => {
    setDataComplete({
      tituloSeccion,
      diseñoSeleccionado,
      reportes,
    });
  }, [tituloSeccion, diseñoSeleccionado, reportes]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddReporte = () => {
    setReportes((prev) => [
      ...prev,
      { id: Date.now(), titulo: "", descripcion: "", url: "", buttonContent: "", buttonColor: "", buttonTextColor: "", buttonUrl: "" },
    ]);
  };

  const handleDeleteReporte = (index) => {
    setReportes((prev) => prev.filter((_, i) => i !== index));
    if (expandedIndex === index) setExpandedIndex(-1);
  };

  const handleSave = () => {
    setLoading(true);
    onSave(dataComplete.tituloSeccion, dataComplete.diseñoSeleccionado, dataComplete.reportes);
    setLoading(false);
    handleClose();
  };

  const handleAccordionChange = (index) => {
    setExpandedIndex((prev) => (prev === index ? -1 : index));
  };

  return (
    <>
      <Tooltip title="Agregar Reporte" arrow>
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleOpen}
          sx={{
            backgroundColor: "white",
            position: "relative",
            bottom: 16,
            left: "46.5rem",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <AddIcon style={{ color: "#0000008a" }} />
        </Fab>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: 800 },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Configuración de Reportes
          </Typography>

          {/* Configuración de la sección */}
          <Typography variant="h6" sx={{ mb: 1 }}>
            Configuración de Sección
          </Typography>
          <TextField
            fullWidth
            label="Título de la Sección"
            value={tituloSeccion}
            onChange={(e) => setTituloSeccion(e.target.value)}
            margin="normal"
          />

          {/* Selección de Diseño */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="select-diseño-label">Seleccionar Diseño</InputLabel>
            <Select
              labelId="select-diseño-label"
              value={diseñoSeleccionado}
              onChange={(e) => setDiseñoSeleccionado(e.target.value)}
              label="Seleccionar Diseño"
            >
              <MenuItem value="ui1">Diseño 1</MenuItem>
              <MenuItem value="ui2">Diseño 2</MenuItem>
              <MenuItem value="ui3">Diseño 3</MenuItem>
            </Select>
          </FormControl>

          {/* Lista de reportes */}
          <Typography variant="h6" sx={{ mt: 3 }}>
            Lista de Reportes
          </Typography>
          {reportes.length === 0 && (
            <Typography variant="body2" sx={{ mb: 2, color: "gray" }}>
              No hay reportes disponibles.
            </Typography>
          )}
          {reportes.map((reporte, index) => (
            <Accordion
              key={reporte.id || index}
              expanded={expandedIndex === index}
              onChange={() => handleAccordionChange(index)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    {reporte.titulo || "Nuevo Reporte"}
                  </Typography>
                  <IconButton
                    color="error"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteReporte(index);
                    }}
                    aria-label="delete"
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Título"
                      value={reporte.titulo}
                      onChange={(e) =>
                        setReportes((prev) =>
                          prev.map((item, i) =>
                            i === index ? { ...item, titulo: e.target.value } : item
                          )
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Descripción"
                      value={reporte.descripcion}
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 220 }}
                      onChange={(e) =>
                        setReportes((prev) =>
                          prev.map((item, i) =>
                            i === index
                              ? { ...item, descripcion: e.target.value }
                              : item
                          )
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="URL del Reporte"
                      value={reporte.url}
                      onChange={(e) =>
                        setReportes((prev) =>
                          prev.map((item, i) =>
                            i === index ? { ...item, url: e.target.value } : item
                          )
                        )
                      }
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Contenido del Botón"
                        value={reporte.buttonContent || ""}
                        onChange={(e) =>
                          setReportes((prev) =>
                            prev.map((item, i) =>
                              i === index
                                ? { ...item, buttonContent: e.target.value }
                                : item
                            )
                          )
                        }
                        sx={{ mt: 2 }}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        fullWidth
                        label="Fondo del Botón (Hex)"
                        value={reporte.buttonColor || ""}
                        type="color"
                        onChange={(e) =>
                          setReportes((prev) =>
                            prev.map((item, i) =>
                              i === index
                                ? { ...item, buttonColor: e.target.value }
                                : item
                            )
                          )
                        }
                        sx={{ mt: 2 }}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        fullWidth
                        label="Color del Texto del Botón"
                        value={reporte.buttonTextColor || ""}
                        type="color"
                        onChange={(e) =>
                          setReportes((prev) =>
                            prev.map((item, i) =>
                              i === index
                                ? { ...item, buttonTextColor: e.target.value }
                                : item
                            )
                          )
                        }
                        sx={{ mt: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="URL del Botón"
                        value={reporte.buttonUrl || ""}
                        onChange={(e) =>
                          setReportes((prev) =>
                            prev.map((item, i) =>
                              i === index
                                ? { ...item, buttonUrl: e.target.value }
                                : item
                            )
                          )
                        }
                        sx={{ mt: 2 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}

          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddReporte}
            sx={{ mt: 2 }}
          >
            Agregar Reporte
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Guardar Todo"}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ReporteForm;
