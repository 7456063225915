import React from 'react';
import ProductCard from './ProductCard';
import ProductCardSkeleton from './ProductCardSkeleton';

const ProductList = ({ isTikTokEnabled,products, onEdit, onDelete, loading ,isAdminRoute , storeName}) => {
  const skeletonCount = 8; // Puedes ajustar este número
  const skeletonArray = Array.from({ length: skeletonCount });



  return (
    <div className="row">
      {loading
        ? skeletonArray.map((_, index) => (
            <ProductCardSkeleton key={index} />
          ))
        : products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onEdit={onEdit}
              onDelete={onDelete}
              isAdminRoute={isAdminRoute}
              storeName={storeName}
              isTikTokEnabled={isTikTokEnabled}
            />
          ))}
    </div>
  );
};

export default ProductList;
