import React from 'react';

const CardServicio3 = ({ icono, titulo, descripcion, color, textoEnlace, href, hasLink }) => {
    return (
        <div className="container mx-auto flex flex-col">
            <div className="flex flex-col flex-wrap -mb-10 lg:text-left text-center">
                <div className="flex flex-col mb-[3.5rem] lg:items-start items-center">
                    <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-2" style={{ backgroundColor: color }}>
                        <img
                            src={icono}
                            alt={`${titulo} icon`}
                            className="object-contain w-6 h-6"
                        />
                    </div>
                    <div className="flex-grow">
                        <h2 className="text-lg title-font font-semibold mb-3 lg:text-left">
                            {titulo}
                        </h2>
                        <p className="leading-relaxed text-base lg:text-left">{descripcion}</p>
                        {hasLink && (
                            <a className="mt-3 text-indigo-400 inline-flex items-center lg:flex" href={href}>
                                {textoEnlace}
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </a>
                        )}
                    </div>
                </div>
            </div>
      </div>
    );
};

export default CardServicio3;