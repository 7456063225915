import React from "react";
import Ui1 from "./Ui1";
import Ui2 from "./Ui2";
import Ui3 from "./Ui3";

const CardReporte = ({
  designVariant, 
  id,
  reportUrl,
  title,
  button,
  description,
  buttonColor,
  url1,
  button2,
  buttonColor2,
  url2,
  fontColor1,
  fontColor2
}) => {





  switch (designVariant) {
    case "ui2":
      return (
        <Ui2
            id={id}
            reportUrl={reportUrl}
            title={title}
            button={button}
            buttonColor={buttonColor}
            description={description}
            url1={url1}
            button2={button2}
            buttonColor2={buttonColor2}
            url2={url2}
            fontColor1={fontColor1}
            fontColor2={fontColor2}
        />
      );
    case "ui3":
      return (
        <Ui3
          id={id}
          reportUrl={reportUrl}
          title={title}
          button={button}
          buttonColor={buttonColor}
          description={description}
          url1={url1}
          button2={button2}
          buttonColor2={buttonColor2}
          url2={url2}
          fontColor1={fontColor1}
          fontColor2={fontColor2}
        />
      );
    default:

      return (
        
        <Ui1
            id={id}
            reportUrl={reportUrl}
            title={title}
            button={button}
            buttonColor={buttonColor}
            description={description}
            url1={url1}
            button2={button2}
            buttonColor2={buttonColor2}
            url2={url2}
            fontColor1={fontColor1}
            fontColor2={fontColor2}
        />
      );
  }
};

export default CardReporte;
