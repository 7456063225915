import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const PoliticaPrivacidadEditButton = ({ onClick }) => (
    <Tooltip title="Editar Politicas" arrow>
        <Fab color="primary"
            aria-label="edit"
            onClick={onClick}
            style={{
                position: 'absolute', // Fija el botón en un lugar constante en la pantalla
                bottom: '1rem', // Espaciado desde el borde inferior
                right: '1rem', // Espaciado desde el borde derecho
                backgroundColor: 'white', // Fondo del botón
            }}
        >
        <EditIcon style={{ color: '#0000008a' }}/>
        </Fab>
    </Tooltip>
);

export default PoliticaPrivacidadEditButton;
