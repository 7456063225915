import React, { useState, useEffect } from "react";
import {Modal,Box,Button,TextField,IconButton,Typography,Stack,Accordion,AccordionSummary,AccordionDetails,CircularProgress,Grid,FormControlLabel,Checkbox} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete } from "@mui/icons-material";
import LZString from "lz-string";
import { uploadFile } from '../../../../utils/firebaseUtils'; 

const ServicioForm = ({ open, onClose, onSave, initialServicios, storeName }) => {

  const [servicios, setServicios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [tituloSeccion, setTituloSeccion] = useState("");
  const [disenoSeleccionado, setDisenoSeleccionado] = useState("CardServicio1"); 
  const [image, setImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const disenos = [
    {
      value: "CardServicio1",
      label: "Diseño 1",
      image: "https://via.placeholder.com/80x40/ff0000/ffffff?text=1",
    },
    {
      value: "CardServicio2",
      label: "Diseño 2",
      image: "https://via.placeholder.com/80x40/00ff00/000000?text=2",
    },
    {
      value: "CardServicio3",
      label: "Diseño 3",
      image: "https://via.placeholder.com/80x40/0000ff/ffffff?text=3",
    },
    {
      value: "CardServicio4",
      label: "Diseño 4",
      image: "https://via.placeholder.com/80x40/0000ff/ffffff?text=3",
    },
  ];
  
  useEffect(() => {
    const isArray = Array.isArray(initialServicios);
    const arrayServicios = isArray
      ? initialServicios || []
      : initialServicios?.servicios || [];
    setServicios(arrayServicios);
    setTituloSeccion(initialServicios?.tituloSeccion || "");
    setDisenoSeleccionado(initialServicios?.disenoSeleccionado || disenos[0].value);
    setUploadedImageUrl(initialServicios?.image || "");
    setLoading(false);
  }, [initialServicios]);
  
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    setServicios((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const handleAddServicio = () => {
    setServicios((prev) => [
      ...prev,
      {
        id: Date.now(),
        titulo: "",
        descripcion: "",
        icono: "",
        color: "#d9ddf1",
        image: "",
      },
    ]);
  };

  const handleDeleteServicio = (index) => {
    setServicios((prev) => prev.filter((_, i) => i !== index));
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else if (expandedIndex > index) {
      setExpandedIndex((prev) => prev - 1);
    }
  };

  const handleSaveAll = async () => {
    try {
      const dataToCompress = {
        tituloSeccion,
        disenoSeleccionado,
        image: uploadedImageUrl,
        servicios,
      };

      const compressedData = LZString.compressToBase64(JSON.stringify(dataToCompress));
      onSave(compressedData);
      onClose();
    } catch (error) {
      console.error("Error al guardar el servicio:", error);
    }
  };

  const handleMainImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {setImage(reader.result);};
      reader.readAsDataURL(selectedFile);

      try {
        const filePath = `${storeName}/servicio/imgPrincipal`;
        const uploadedUrl = await uploadFile(selectedFile, filePath);
        setUploadedImageUrl(uploadedUrl);
      } catch (error) {
        console.error("Error al subir la imagen principal:", error);
        alert("Hubo un problema al subir la imagen principal. Inténtalo nuevamente.");
      }
    }
  };
  
  const handleFileChange = async (index, e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        const filePath = `${storeName}/servicio/iconos`;
        const iconUrl = await uploadFile(selectedFile, filePath);
        setServicios((prev) => {
          const updated = [...prev];
          updated[index] = { ...updated[index], icono: iconUrl };
          return updated;
        });
      } catch (error) {
        console.error("Error al subir el archivo:", error);
        alert("Hubo un problema al subir el ícono. Inténtalo nuevamente.");
      }
    }
  };
  
  const handleAccordionChange = (panelIndex) => {
    setExpandedIndex((prevIndex) => (prevIndex === panelIndex ? -1 : panelIndex));
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-service-modal"
      aria-describedby="modal-to-add-service"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 800 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {/*<h2 id="add-service-modal">Editar Servicio</h2> */}
        <Typography variant="h5" sx={{ mb: 2 }}>
          Editar Servicios
        </Typography>

        {loading ? (
          // Spinner de carga
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* ------------------------------------- */}
            {/*  Bloque para “Elemento Extra”          */}
            {/* ------------------------------------- */}
            <Typography variant="h6" sx={{ mb: 1 }}>
              Configuración de Sección
            </Typography>

            {/* Título de la Sección */}
            <TextField
              fullWidth
              label="Título de la Sección"
              value={tituloSeccion}
              onChange={(e) => setTituloSeccion(e.target.value)}
              margin="normal"
            />

            {/* Selección de Diseño */}
            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
              Elige un Diseño
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              {disenos.map((diseno) => (
                <Box
                  key={diseno.value}
                  onClick={() => setDisenoSeleccionado(diseno.value)}
                  sx={{
                    border:
                      disenoSeleccionado === diseno.value ? "2px solid blue": "1px solid #ccc",
                    borderRadius: 2,
                    p: 1,
                    cursor: "pointer",
                    textAlign: "center",
                    boxShadow: disenoSeleccionado === diseno.value ? "0 0 10px rgba(0, 0, 255, 0.5)" : "none",
                    transition: "border 0.3s, box-shadow 0.3s",
                  }}
                >
                  <img
                    src={diseno.image}
                    alt={diseno.label}
                    style={{ display: "block", margin: "0 auto", maxWidth: "100%", height: "auto" }}
                  />
                </Box>
              ))}
            </Stack>

            {disenoSeleccionado === "CardServicio3" && (
              <>
                <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
                  Subir Imagen Principal
                </Typography>
                <Button variant="outlined" component="label" fullWidth sx={{ mt: 2 }}>
                  {image ? "Cambiar Imagen" : "Subir Imagen"}
                  <input type="file" accept="image/*" hidden onChange={handleMainImageChange} />
                </Button>
                {image && (
                  <Box mt={2} textAlign="center">
                    <img
                      src={image} // Mostrar previsualización de la imagen seleccionada
                      alt="Previsualización"
                      style={{ width: "100%", maxHeight: "200px", objectFit: "contain" }}
                    />
                  </Box>
                )}
              </>
            )}

            {/* ------------------------------------- */}
            {/*  Bloque para servicios             */}
            {/* ------------------------------------- */}
            <Typography variant="h6" sx={{ mt: 3 }}>
              Lista de Servicios
            </Typography>

            {servicios.length === 0 && (
              <Typography variant="body2" sx={{ mb: 2, color: "gray" }}>
                No hay servicios disponibles.
              </Typography>
            )}

            {servicios.map((servicio, index) => (
              <Accordion
                key={servicio.id || index}
                expanded={expandedIndex === index}
                onChange={() => handleAccordionChange(index)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {servicio.titulo || "Nuevo Servicio"}
                    </Typography>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); // Evita que se expanda/cierre el accordion
                        handleDeleteServicio(index);
                      }}
                      aria-label="delete"
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Stack spacing={2}>
                        {/* Título */}
                        <TextField
                          fullWidth
                          label="Título"
                          name="titulo"
                          value={servicio.titulo}
                          onChange={(e) => handleChange(index, e)}
                        />

                        {/* Descripción */}
                        <TextField
                          fullWidth
                          label="Descripción"
                          name="descripcion"
                          multiline
                          rows={4}
                          value={servicio.descripcion}
                          onChange={(e) => handleChange(index, e)}
                        />

                        {disenoSeleccionado != "CardServicio4" && (
                          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                            {/* Checkbox ocupa 2 columnas */}
                            <Box sx={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!servicio.hasLink}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      setServicios((prev) => {
                                        const updated = [...prev];
                                        updated[index] = { ...updated[index], hasLink: isChecked };
                                        return updated;
                                      });
                                    }}
                                  />
                                }
                                label="¿Activar enlace?"
                              />
                            </Box>

                            {/* Texto del enlace ocupa 5 columnas */}
                            <Box sx={{ flex: 5 }}>
                              <TextField
                                label="Texto del enlace"
                                name="textoEnlace"
                                value={servicio.textoEnlace || ""}
                                onChange={(e) => handleChange(index, e)}
                                fullWidth
                                disabled={!servicio.hasLink}
                              />
                            </Box>

                            {/* Destino URL ocupa 5 columnas */}
                            <Box sx={{ flex: 5 }}>
                              <TextField
                                label="Destino (URL)"
                                name="href"
                                value={servicio.href || ""}
                                onChange={(e) => handleChange(index, e)}
                                fullWidth
                                disabled={!servicio.hasLink}
                              />
                            </Box>
                          </Stack>
                        )}
                        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                          <Box sx={{ flex: 1 }}>
                            {disenoSeleccionado != "CardServicio4" && (
                              <TextField
                                fullWidth
                                label="Seleccionar Color"
                                type="color"
                                name="color"
                                value={servicio.color || "#ffffff"}
                                onChange={(e) => handleChange(index, e)}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  "& input": {
                                    padding: 0,
                                    height: "40px",
                                    cursor: "pointer",
                                  },
                                }}
                              />
                            )}
                            <Button
                              variant="outlined"
                              component="label"
                              fullWidth
                              style={{ marginTop: '16px' }}
                            >
                              {loading ? 'Subiendo ícono...' : 'Subir Ícono'}
                              <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleFileChange(index, e)}
                                disabled={loading}
                              />
                            </Button>
                          </Box>
                          <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box 
                              mt={{ xs: 2, sm: 0 }} 
                              textAlign="center"
                              sx={{
                                width: 60,          
                                height: 60,           
                                margin: "0 auto",     
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                //backgroundColor: servicio.color || "#ffffff",
                                backgroundColor: disenoSeleccionado !== "CardServicio4" ? servicio.color || "#ffffff" : "transparent", // Condición aplicada aquí
                                borderRadius: "50%",
                              }}
                            >
                              {servicio.icono && (
                                <img
                                  src={servicio.icono}
                                  alt="Ícono del servicio"
                                  style={{ 
                                    width: "50%", 
                                    height: "50%", 
                                    objectFit: "contain" 
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Stack>

                      </Stack>
                    </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}

        {/* Botón para agregar nuevo servicio */}
          {!loading && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddServicio}
              sx={{ mt: 2 }}
            >
              Agregar Servicio
            </Button>
          )}
  
          {/* Botón para guardar todos los cambios */}
          {!loading && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleSaveAll}
            >
              Guardar Todo
            </Button>
          )}
      </Box>
    </Modal>
  );
};

export default ServicioForm;
