import React, { useState, useEffect } from 'react';
import ProductList from './components/ProductList';
import ProductFormModal from './components/ProductFormModal';
import ProductoAddButton from './components/button/ProductoAddButton';
import { Fab, Box, Tooltip, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  uploadFile,
  saveToFirestore,
  getDocuments,
  getDocumentById,
  deleteDocument,
} from '../../utils/firebaseUtils';
import LZString from 'lz-string';
import { auth } from '../../firebaseConfig';
import { useNavigate } from 'react-router-dom'; 
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

const ProductoManager = ({ isTikTokEnabled, storeName = "localhost" , isAdminRoute }) => {
  
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [user, setUser] = useState(null); 
  const navigate = useNavigate();
  const { tienda } = useParams();

  useEffect(() => {
    loadProducts();
  }, [storeName]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const loadProducts = async () => {
  
    try {
      setLoading(true); // Comenzamos la carga
      const collectionPath = `${storeName}/inventario/productos`;
      const productsData = await getDocuments(collectionPath);

      // Mapeamos los productos y descomprimimos el campo 'data'
      const productsList = productsData.map((product) => {
        let additionalData = {};
        if (product.data) {
          const decompressedData = LZString.decompressFromBase64(product.data);
          additionalData = JSON.parse(decompressedData);
        }
        return {
          id: product.id,
          name: product.name,
          productType: product.productType,
          ...additionalData,
        };
      });
      setProducts(productsList);

    } catch (error) {
      console.error('Error al cargar productos:', error);
    } finally {
      setLoading(false); // Terminamos la carga
    } };

  const handleDelete = async (productId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este producto?')) {
      try {
        setLoading(true); // Comenzamos la carga
        const collectionPath = `${storeName}/inventario/productos`;
        await deleteDocument(collectionPath, productId);
        // Actualizar la lista de productos
        await loadProducts();
        console.log('Lista de Productos:');
      } catch (error) {
        console.error('Error al eliminar el producto:', error);
      } finally {
        setLoading(false); // Terminamos la carga
      }
    }};

  const handleOpen = () => {
    setProductToEdit(null); // Limpia el producto para un nuevo registro
    setOpen(true);};

  const handleEdit = async (product) => {
    try {
      const collectionPath = `${storeName}/inventario/productos`;
      const productData = await getDocumentById(collectionPath, product.id);

      if (productData) {
        // Descomprimir el campo 'data'
        let additionalData = {};
        if (productData.data) {
          const decompressedData = LZString.decompressFromBase64(productData.data);
          additionalData = JSON.parse(decompressedData);
        }

        setProductToEdit({
          id: product.id,
          name: productData.name,
          productType: productData.productType,
          ...additionalData,
        });

        setOpen(true);
      } else {
        console.error('Producto no encontrado para editar');
      }
    } catch (error) {
      console.error('Error al cargar el producto para editar:', error);
    }};

  const handleClose = () => setOpen(false);

  const handleProductSubmit = async (productData) => {
   
    try {
      setLoading(true); // Comenzamos la carga
      const productId = productData.id || Date.now().toString(); // Generar un ID único si es nuevo
      productData.id = productId;
      const collectionPath = `${storeName}/inventario/productos`;
      // Preparar datos para guardar

      const { name, productType, ...restData } = productData;

      // Comprimir datos adicionales
      const compressedData = LZString.compressToBase64(JSON.stringify(restData));

      // Datos a guardar
      const dataToSave = {
        name,
        productType,
        data: compressedData,
      };

      // Guardar en Firestore
      await saveToFirestore(collectionPath, productId, dataToSave);
      // Recargar la lista de productos
      await loadProducts();
      handleClose();
    } catch (error) {
      console.error('Error al guardar el producto:', error);
    } finally {
      setLoading(false); // Terminamos la carga
    }};

  return (
    <>
    
      <section className="mt-5"  style={{ position: 'relative' }} >

        <ProductoAddButton user={user} onClick={handleOpen} iconName={"DesignServices"} buttonText={"Producto"} top={"2rem"} />
        <ProductoAddButton user={user} onClick={handleOpen} iconName={"Add"} buttonText={"Producto"} top={"5rem"}  />

        <h1
          className="text-2xl font-semibold my-5"
          style={{ color: '#255ce0', fontSize: '35px', fontWeight: '800' }}
        >
          Productos
        </h1>


         <div className="container">
 
                  <ProductList
                    products={products}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    loading={loading}
                    isAdminRoute={isAdminRoute}
                    storeName={storeName}
                    isTikTokEnabled={isTikTokEnabled}
                  />
             
            </div>
         
 
      </section>

      {/* Modal para registrar o editar productos */}
      <ProductFormModal
        storeName={storeName}
        open={open}
        handleClose={handleClose}
        handleSubmit={handleProductSubmit}
        productData={productToEdit}
      />
    </>
  );
};

export default ProductoManager;
