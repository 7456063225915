import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  CircularProgress,
  Grid,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Paper,
} from "@mui/material";
import LZString from "lz-string";
import { uploadFile } from "../../../utils/firebaseUtils";

const GaleriaModal = ({
  open,
  onClose,
  onSave,
  initialGaleria,
  storeName,
  onTempUpdate,
}) => {
  const [galeria, setGaleria] = useState({});
  const [loading, setLoading] = useState(true);

  const [tituloSeccion, setTituloSeccion] = useState("");
  const [tituloColor, setTituloColor] = useState("");
  const [fondoColor, setFondoColor] = useState("");
  const [roundedValue, setRoundedValue] = useState("");
  const [disenoSeleccionado, setDisenoSeleccionado] = useState("GaleriaDesing1");

  const [isInitialized, setIsInitialized] = useState(false);

  const disenos = [
    {
      value: "GaleriaDesing1",
      label: "Diseño 1",
      image: "https://via.placeholder.com/80x40/ff0000/ffffff?text=1",
    },
    // Puedes agregar más diseños aquí
  ];

  useEffect(() => {
    if (!isInitialized && open) {
      const data = initialGaleria || {};

      setTituloSeccion(data.tituloSeccion || "");
      setTituloColor(data.tituloColor || "#000000");
      setFondoColor(data.fondoColor || "#ffffff");
      setDisenoSeleccionado(data.disenoSeleccionado || "GaleriaDesing1");

      // Galería inicial
      const singleGaleriaObj = data.galeria || {
        id: Date.now(),
        // Imágenes
        image1: "",
        image1HasLink: false,
        image1Href: "",
        image2: "",
        image2HasLink: false,
        image2Href: "",
        image3: "",
        image3HasLink: false,
        image3Href: "",
        image4: "",
        image4HasLink: false,
        image4Href: "",
        image5: "",
        image5HasLink: false,
        image5Href: "",
        image6: "",
        image6HasLink: false,
        image6Href: "",
        image7: "",
        image7HasLink: false,
        image7Href: "",
        // Nuevo campo para border-radius
        rounded: "",
      };

      setGaleria(singleGaleriaObj);
      setLoading(false);
      setIsInitialized(true);
    }
  }, [initialGaleria, open, isInitialized]);

  /**
   * Actualiza la galería y notifica al padre si se requiere vista previa en tiempo real.
   */
  const updateGaleriaState = (field, value) => {
    setGaleria((prev) => {
      const updated = { ...prev, [field]: value };

      if (onTempUpdate) {
        onTempUpdate({
          tituloSeccion,
          tituloColor,
          fondoColor,
          disenoSeleccionado,
          galeria: updated,
        });
      }

      return updated;
    });
  };

  /**
   * Maneja la subida de archivos a Firebase y actualiza el state
   */
  const handleFileChange = async (fieldName, e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        setLoading(true);
        const filePath = `${storeName}/galeria/imagenes`;
        const fileUrl = await uploadFile(selectedFile, filePath);
        setLoading(false);

        updateGaleriaState(fieldName, fileUrl);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
        alert("Hubo un problema al subir la imagen. Inténtalo nuevamente.");
        setLoading(false);
      }
    }
  };

  /**
   * Guarda todos los cambios de la galería en formato comprimido (base64)
   */
  const handleSaveAll = async () => {
    try {
      const dataToCompress = {
        tituloSeccion,
        tituloColor,
        fondoColor,
        disenoSeleccionado,
        galeria,
      };

      const compressedData = LZString.compressToBase64(
        JSON.stringify(dataToCompress)
      );
      onSave(compressedData);
      onClose();
    } catch (error) {
      console.error("Error al guardar la galería:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-galeria-modal"
      aria-describedby="modal-to-edit-galeria"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 800 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
          Editar Galería
        </Typography>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/** SECCIÓN: Configuración de la sección */}
            <Paper sx={{ p: 2, mb: 2 }} elevation={1}>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
                Configuración de la Sección
              </Typography>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth
                    label="Título de la Sección"
                    value={tituloSeccion}
                    onChange={(e) => setTituloSeccion(e.target.value)}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel sx={{ mb: 1 }}>Color del Título</InputLabel>
                  <input
                    type="color"
                    value={tituloColor}
                    onChange={(e) => setTituloColor(e.target.value)}
                    style={{ width: "100%", height: "40px", border: "none" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel sx={{ mb: 1 }}>Color del Fondo</InputLabel>
                  <input
                    type="color"
                    value={fondoColor}
                    onChange={(e) => setFondoColor(e.target.value)}
                    style={{ width: "100%", height: "40px", border: "none" }}
                  />
                </Grid>
              </Grid>
            </Paper>

            {/** SECCIÓN: Selección de diseño */}
            <Paper sx={{ p: 2, mb: 2 }} elevation={1}>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
                Elige un Diseño
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                {disenos.map((diseno) => (
                  <Box
                    key={diseno.value}
                    onClick={() => setDisenoSeleccionado(diseno.value)}
                    sx={{
                      border:
                        disenoSeleccionado === diseno.value
                          ? "2px solid blue"
                          : "1px solid #ccc",
                      borderRadius: 2,
                      p: 1,
                      cursor: "pointer",
                      textAlign: "center",
                      boxShadow:
                        disenoSeleccionado === diseno.value
                          ? "0 0 10px rgba(0, 0, 255, 0.5)"
                          : "none",
                      transition: "border 0.3s, box-shadow 0.3s",
                    }}
                  >
                    <img
                      src={diseno.image}
                      alt={diseno.label}
                      style={{
                        display: "block",
                        margin: "0 auto",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {diseno.label}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Paper>

            {/** SECCIÓN: Lista de galerías */}
            <Paper sx={{ p: 2, mb: 2 }} elevation={1}>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
                Lista de Imágenes
              </Typography>

              {/**
               * NUEVO: Campo para "border-radius" de todas las imágenes
               * Agregamos un Box de previsualización para que el usuario
               * vea en tiempo real cómo se redondearían los bordes.
               */}
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
                  Bordes redondeados para las imágenes
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextField
                    fullWidth
                    placeholder="Ej: 10px o 50%"
                    value={galeria.rounded || ""}
                    onChange={(e) =>
                      updateGaleriaState("rounded", e.target.value)
                    }
                  />
                  {/* Previsualización del border-radius */}
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      border: "1px solid #ccc",
                      borderRadius: galeria.rounded || "0px",
                      backgroundColor: "#f0f0f0",
                      flexShrink: 0,
                      transition: "border-radius 0.3s",
                      // Podrías incluso mostrar una imagen real aquí en vez de un color
                      // backgroundImage: 'url("...")',
                      // backgroundSize: "cover",
                    }}
                  />
                </Stack>
              </Box>

              {/* -- Imagen #1 -- */}
              
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen #1
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                  <Button variant="outlined" component="label">
                    Subir Imagen #1
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleFileChange("image1", e)}
                      disabled={loading}
                    />
                  </Button>
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image1HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image1HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #1"
                    value={galeria.image1Href || ""}
                    onChange={(e) => updateGaleriaState("image1Href", e.target.value)}
                    disabled={!galeria.image1HasLink}
                  />
                </Grid>
              </Grid>


              {/* -- Imagen #2 -- */}

              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen #2
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                  <Button variant="outlined" component="label">
                    Subir Imagen #2
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleFileChange("image2", e)}
                      disabled={loading}
                    />
                  </Button>
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image2HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image2HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #2"
                    value={galeria.image2Href || ""}
                    onChange={(e) => updateGaleriaState("image2Href", e.target.value)}
                    disabled={!galeria.image2HasLink}
                  />
                </Grid>
              </Grid>

              {/* -- Imagen #3 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen #3
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                  <Button variant="outlined" component="label">
                    Subir Imagen #3
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleFileChange("image3", e)}
                      disabled={loading}
                    />
                  </Button>
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image3HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image3HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #3"
                    value={galeria.image3Href || ""}
                    onChange={(e) => updateGaleriaState("image3Href", e.target.value)}
                    disabled={!galeria.image3HasLink}
                  />
                </Grid>
              </Grid>


              {/* -- Imagen #4 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen #4
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                  <Button variant="outlined" component="label">
                    Subir Imagen #4
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleFileChange("image4", e)}
                      disabled={loading}
                    />
                  </Button>
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image4HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image4HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #4"
                    value={galeria.image4Href || ""}
                    onChange={(e) => updateGaleriaState("image4Href", e.target.value)}
                    disabled={!galeria.image4HasLink}
                  />
                </Grid>
              </Grid>

              {/* -- Imagen #5 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen #5
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                  <Button variant="outlined" component="label">
                    Subir Imagen #5
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleFileChange("image5", e)}
                      disabled={loading}
                    />
                  </Button>
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image5HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image5HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #5"
                    value={galeria.image5Href || ""}
                    onChange={(e) => updateGaleriaState("image5Href", e.target.value)}
                    disabled={!galeria.image5HasLink}
                  />
                </Grid>
              </Grid>

              {/* -- Imagen #6 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen #6
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                  <Button variant="outlined" component="label">
                    Subir Imagen #6
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleFileChange("image6", e)}
                      disabled={loading}
                    />
                  </Button>
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image6HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image6HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #6"
                    value={galeria.image6Href || ""}
                    onChange={(e) => updateGaleriaState("image6Href", e.target.value)}
                    disabled={!galeria.image6HasLink}
                  />
                </Grid>
              </Grid>

              {/* -- Imagen #7 -- */}
              <Typography variant="subtitle1" sx={{ mt: 1, fontWeight: 600 }}>
                Imagen #7
              </Typography>
              <Grid container spacing={2} alignItems="center">
                {/* Botón Subir Imagen */}
                <Grid item xs="auto">
                  <Button variant="outlined" component="label">
                    Subir Imagen #7
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => handleFileChange("image7", e)}
                      disabled={loading}
                    />
                  </Button>
                </Grid>

                {/* Checkbox */}
                <Grid item xs="auto">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={galeria.image7HasLink || false}
                        onChange={(e) =>
                          updateGaleriaState("image7HasLink", e.target.checked)
                        }
                      />
                    }
                    label="¿Activar enlace?"
                  />
                </Grid>

                {/* TextField que ocupa el espacio restante */}
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Destino (URL) #7"
                    value={galeria.image7Href || ""}
                    onChange={(e) => updateGaleriaState("image7Href", e.target.value)}
                    disabled={!galeria.image7HasLink}
                  />
                </Grid>
              </Grid>
            </Paper>

            {/** BOTÓN para guardar todos los cambios */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, py: 1.5, fontWeight: "bold" }}
              onClick={handleSaveAll}
            >
              Guardar Todo
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default GaleriaModal;