import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // Para capturar los parámetros de la URL
import { functions } from '../../firebaseConfig'; // Importa correctamente tu configuración de Firebase
import { httpsCallable } from 'firebase/functions';
import TikTokManager from '../TikTok/TiktokManager';
import useStoreName from '../../hooks/useStoreName';

const CallBackTiktok = () => {
    
    const { storeName, isAdminRoute } = useStoreName();
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code'); 
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const accesstoken = httpsCallable(functions, 'accesstoken');


    useEffect(() => {
      
        if (code) {
            const obtenerAccessToken = async () => {
                try {
                    // Llamada a la función 'accesstoken' pasándole el código
                    const response = await accesstoken({ code });
                    console.log(response);
                    // Guardar el access token en el estado
                    setData(response.data);

                    // Guardar el access token en el localStorage
                     // Almacenar otros datos si lo necesitas
                    
                    
                    const tienda =  localStorage.getItem('tienda');
                    const urlFinal = `${process.env.REACT_APP_URL_SSL}://${process.env.REACT_APP_URL_REPLACE}/${tienda}/administrar`;
                    console.log(urlFinal); 
                    localStorage.setItem(`${tienda}_tiktok_refresh_token`, response.data.refresh_token);  // Si también quieres guardar el refresh token
                    localStorage.setItem(`${tienda}_tiktok_access_token`, response.data.access_token);
                    localStorage.setItem(`${tienda}_tiktok_expires_in`, response.data.expires_in); 
                    window.location.href = urlFinal;


                } catch (err) {
                    // Si hay un error, guardar el error en el estado
                    setError(err.message || 'Error al obtener el access token');
                    console.log(err);
                }
            };

            obtenerAccessToken();
        }
    }, []); 


    useEffect(() => {
        console.log(data);
        alert(data.access_token)
        
    }, [data]); 






    return (
        <div>
            
            <h1>Callback de Tiktok</h1>
            
            {error && <p style={{ color: 'red' }}>Error: {error}</p>}
            
            {data ? (
                <div>

                    <h2>Access Token Obtenido</h2>
                    <p>{data}</p>
                </div>
            ) : (
                <>
                <p>Obteniendo el Access Token...</p>
                <p>codigo autorizacion: {code}</p>
                </>
            )}

     




        </div>
    );
};

export default CallBackTiktok;
