import React from 'react';
import { Fab, Tooltip,IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';


const BlogDeleteButton = ({ onClick }) => (
    
    <Tooltip arrow>
        <Fab
            //color="secondary"
            aria-label="delete"
            onClick={onClick}
            sx={{
                backgroundColor: 'white',
                boxShadow: 1,
                '&:hover': { backgroundColor: 'lightgrey' },
                width:'40px',
                height:'40px',
            }}
        >
            <DeleteIcon style={{ color: '#0000008a' }} />
        </Fab>
    </Tooltip>
);

export default BlogDeleteButton;