import React from 'react';
import { Box, Grid } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';
import CardServicio from './desings/CardServicio';

const ServicioPreview = ({ serviciosArray, width = 300, height = 200, user }) => {
  const arrayServicios = serviciosArray?.servicios || [];
  const diseno = serviciosArray?.disenoSeleccionado || "CardServicio1";

  // Determinar si se debe mostrar la imagen (solo para CardServicio3)
  const showImage = diseno === "CardServicio3" && serviciosArray;
  const image = showImage ? serviciosArray.image || "https://dummyimage.com/460x500" : null;

  if (!serviciosArray) {
    return (
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <SkeletonLoader width={width} height={height} />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: height,
        padding: 2,
        borderRadius: 2,
        backgroundColor: 'white',
      }}
    >
      {/* Título de la sección */}
      {diseno != "CardServicio4" && (
        <h1 className="text-2xl font-semibold my-5" style={{ color: '#255ce0', fontSize: '35px', fontWeight: '800' }}>
          {serviciosArray.tituloSeccion || "Servicios"}
        </h1>
      )}

      {/* Mostrar otro título solo para CardServicio4 */}
      {diseno === "CardServicio4" && (
        <h1 className="my-5" style={{ color: '#9EB1CA', fontSize: '40px', fontWeight: '700' }}>
          {serviciosArray.tituloSeccion || "Servicios"}
        </h1>
      )}

      {/* Grid para imagen y tarjetas */}
      <Grid container spacing={4} alignItems="flex-start" sx={{ maxWidth: '1300px', margin: '0 auto', width: '100%' }}>
        {/* Mostrar imagen al lado izquierdo si el diseño es CardServicio3 */}
        {showImage && image && (
          <Grid item xs={12} lg={6}>
            <Box
              component="img"
              src={image}
              alt="feature"
              sx={{
                width: '100%',
                height: {
                  xs: '200px',
                  sm: '250px',
                  md: '300px',
                  lg: '694px',
                },
                borderRadius: 2,
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                border: '1px solid #ddd',
              }}
            />
          </Grid>
        )}

        {/* Renderizar tarjetas de servicios */}
        <Grid item xs={12} lg={showImage ? 6 : 12} className="!pl-0 md:!pl-[32px]">
          {diseno === "CardServicio4" ? (
            // Diseño específico para CardServicio4 con columnas responsivas
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: '1fr', // 1 columna en pantallas muy pequeñas
                  sm: 'repeat(2, 1fr)', // 2 columnas en pantallas pequeñas
                  md: 'repeat(4, 1fr)', // 4 columnas en pantallas medianas o mayores
                },
                gap: '16px',
              }}
              
            >
              {arrayServicios.map((servicio) => (
                <Box
                  key={servicio.id || servicio.titulo}
                  sx={{
                    gridColumn: "span 1",
                  }}

                 

                >
                  <CardServicio
                    id={servicio.id}
                    titulo={servicio.titulo}
                    descripcion={servicio.descripcion}
                    icono={servicio.icono}
                    color={servicio.color}
                    designVariant={diseno}
                    textoEnlace={servicio.textoEnlace}
                    href={servicio.href}
                    hasLink={servicio.hasLink}
                  />
                </Box>
              ))}
            </Box>
          ) : (
            // Diseño genérico para otros tipos (CardServicio1, CardServicio2, etc.)
            <Grid container spacing={2}>
              {arrayServicios.map((servicio) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={showImage ? 12 : 4}
                  lg={showImage ? 12 : 4}
                  key={servicio.id || servicio.titulo}
                >
                  <CardServicio
                    id={servicio.id}
                    titulo={servicio.titulo}
                    descripcion={servicio.descripcion}
                    icono={servicio.icono}
                    color={servicio.color}
                    designVariant={diseno}
                    textoEnlace={servicio.textoEnlace}
                    href={servicio.href}
                    hasLink={servicio.hasLink}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicioPreview;
