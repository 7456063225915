import { useState, useEffect } from 'react';


const useTikTokUserInfo = (accessToken) => {
  
    const [userInfo, setUserInfo] = useState(null); // Almacenar la información del usuario
    const [loading, setLoading] = useState(true); // Estado de carga
    const [error, setError] = useState(null); // Estado de error

  

  useEffect(() => {

    if (!accessToken) return;

    const fetchUserInfo = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch('https://open.tiktokapis.com/v2/user/info/?fields=open_id,union_id,avatar_url,display_name', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`, // Usamos el access token en el header
          },
        });

        if (!response.ok) {
          throw new Error('Error al obtener los datos del usuario');
        }

        const data = await response.json();

        if (data && data.data) {
          setUserInfo(data.data);
        } else {
          throw new Error('Datos de usuario no encontrados');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [accessToken]); 

  return { userInfo, loading, error };
};

export default useTikTokUserInfo;
