import LZString from 'lz-string';

const decompress = (compressedData) => {
  try {
    const decompressedString = LZString.decompressFromBase64(compressedData);
    return JSON.parse(decompressedString);
  } catch (error) {
    console.error('Error al descomprimir los datos:', error);
    // Opcional: puedes devolver un valor predeterminado en caso de error
    return null; // O {}
  }
};

export default decompress;
