import React, { useState, useEffect } from 'react';
import {
  Box,
  Fab,
  Tooltip,
  Modal,
  TextField,
  Button,
  IconButton,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  MenuItem, 
  Select,
  InputLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { auth } from '../../../firebaseConfig';
import { saveToFirestore, uploadFile } from '../../../utils/firebaseUtils';
import LZString from 'lz-string';
import Diseño1 from '../ui/Diseño1';
import Diseño2 from '../ui/Diseño2';
import FooterAddButton from './button/FooterAddButton';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxHeight: '90vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};


const Footer = ({ storeName, compressedFooter, isAdminRoute }) => {
  
  
  const [user, setUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [footerContent, setFooterContent] = useState({
    diseñoSeleccionado: 'diseño1', // Incluye diseñoSeleccionado aquí
    copyright: '© 2025 Mi Tienda',
    quickLinks: [
      { href: 'https://www.ejemplo.com', text: 'Inicio' },
      { href: 'https://www.ejemplo.com/acerca', text: 'Acerca de nosotros' },
    ],
    information: [
      { href: 'https://www.ejemplo.com/contacto', text: 'Contáctanos', image: '' },
    ],
    socialLinks: [
      { icon: '', url: 'https://facebook.com', bgColor: '#3b5998' },
    ],
    footerBackgroundColor: '#f1f1f1',
    footerTextColor: '#000000',
    footerTextoTodo: '#333333',
  });


{/* 
  const [footerContent, setFooterContent] = useState({
    diseñoSeleccionado: 'diseño1', // Incluye diseñoSeleccionado aquí
    copyright: '© 2025 Mi Tienda',
    quickLinks: [
      { href: 'https://www.ejemplo.com', text: 'Inicio' },
      { href: 'https://www.ejemplo.com/acerca', text: 'Acerca de nosotros' },
    ],
    centroAyuda: [
      { href: 'https://www.ejemplo.com', text: 'Centro de Ayuda' },
      { href: 'https://www.ejemplo.com/acerca', text: 'Acerca de nosotros' },
    ],
    information: [
      { href: 'https://www.ejemplo.com/contacto', text: 'Contáctanos', image: '' },
    ],
    socialLinks: [
      { icon: '', url: 'https://facebook.com', bgColor: '#3b5998' },
    ],
    footerBackgroundColor: '#f1f1f1',
    footerTextColor: '#000000',
    footerTextoTodo: '#333333',
  });
*/}


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (compressedFooter) {
      try {
        const decompressedData = LZString.decompressFromBase64(compressedFooter);
        if (decompressedData) {
          const parsedData = JSON.parse(decompressedData);
          if (parsedData) {
            setFooterContent(parsedData); // Recupera diseñoSeleccionado también
          } else {
            console.error('Los datos descomprimidos no son válidos.');
          }
        }
      } catch (error) {
        console.error('Error al descomprimir el footer:', error);
      }
    }
  }, [compressedFooter]);
  
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSave = async () => {
    try {
      const footerJson = JSON.stringify(footerContent); 
      const footer = LZString.compressToBase64(footerJson); 
      await saveToFirestore(storeName, 'config', { footer }); 
      handleCloseModal();
    } catch (error) {
      console.error('Error al guardar el footer:', error);
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFooterContent((prev) => ({ ...prev, [name]: value }));
  };

  const handleLinkChange = (category, index, field, value) => {
    if (!footerContent[category]) return;
    const updatedLinks = [...footerContent[category]];
    if (updatedLinks[index]) {
      updatedLinks[index][field] = value;
      setFooterContent((prev) => ({ ...prev, [category]: updatedLinks }));
    }
  };

  const handleAddLink = (category) => {
    if (!footerContent[category]) return;
    setFooterContent((prev) => ({
      ...prev,
      [category]: [...prev[category], { href: '', text: '' }],
    }));
  };

  const handleRemoveLink = (category, index) => {
    if (!footerContent[category]) return;
    const updatedLinks = footerContent[category].filter((_, i) => i !== index);
    setFooterContent((prev) => ({ ...prev, [category]: updatedLinks }));
  };

  const handleSocialLinkChange = (index, field, value) => {
    if (!Array.isArray(footerContent?.socialLinks)) return;
    const updatedSocialLinks = [...footerContent.socialLinks];
    if (updatedSocialLinks[index]) {
      updatedSocialLinks[index][field] = value;
      setFooterContent((prev) => ({ ...prev, socialLinks: updatedSocialLinks }));
    }
  };

  const handleAddSocialLink = () => {
    setFooterContent((prev) => ({
      ...prev,
      socialLinks: [...prev?.socialLinks || [], { icon: '', url: '', bgColor: '#ffffff' }],
    }));
  };

  const handleRemoveSocialLink = (index) => {
    if (!Array.isArray(footerContent?.socialLinks)) return;
    const updatedSocialLinks = footerContent.socialLinks.filter((_, i) => i !== index);
    setFooterContent((prev) => ({ ...prev, socialLinks: updatedSocialLinks }));
  };

  const handleFileUpload = async (event, index, category, key) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const path = `${storeName}/footer/${category}/${file.name}`;
        const uploadedImageUrl = await uploadFile(file, path);
        setFooterContent((prev) => {
          const updatedCategory = [...prev[category] || []];
          if (updatedCategory[index]) {
            updatedCategory[index][key] = uploadedImageUrl;
          }
          return { ...prev, [category]: updatedCategory };
        });
      } catch (error) {
        console.error('Error al subir la imagen:', error);
      }
    }
  };

  return (
    
    <div style={{ position: 'relative' }}>
    
        {footerContent.diseñoSeleccionado === 'diseño1' ? (
              
              <Diseño1 footerContent={footerContent} storeName={storeName} isAdminRoute={isAdminRoute} />
        
            ) : (
        
              <Diseño2 footerContent={footerContent} storeName={storeName} isAdminRoute={isAdminRoute} />
        
        )}
            

   
      <FooterAddButton  user={user} onClick={handleOpenModal} />


      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Configuración General
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Copyright"
            name="copyright"
            value={footerContent.copyright}
            onChange={handleChange}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <Select
  fullWidth
  value={footerContent.diseñoSeleccionado}
  onChange={(e) =>
    setFooterContent((prev) => ({
      ...prev,
      diseñoSeleccionado: e.target.value, 
    }))
  }
  label="Seleccionar Diseño"
>
  <MenuItem value="diseño1">Diseño 1</MenuItem>
  <MenuItem value="diseño2">Diseño 2</MenuItem>
</Select>


          </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                type="color"
                label="Color de Fondo del Footer"
                name="footerBackgroundColor"
                value={footerContent.footerBackgroundColor}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                type="color"
                label="Color de Texto General"
                name="footerTextoTodo"
                value={footerContent.footerTextoTodo}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                type="color"
                label="Color de los Títulos"
                name="footerTextColor"
                value={footerContent.footerTextColor}
                onChange={handleChange}
              />
            </Grid>
          </Grid>


          {/* Enlaces Rápidos */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Enlaces Rápidos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {footerContent?.quickLinks?.map((link, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <TextField
                    label="Texto"
                    value={link.text}
                    onChange={(e) =>
                      handleLinkChange('quickLinks', index, 'text', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <TextField
                    label="URL"
                    value={link.href}
                    onChange={(e) =>
                      handleLinkChange('quickLinks', index, 'href', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveLink('quickLinks', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={() => handleAddLink('quickLinks')}>
                Agregar Enlace
              </Button>
            </AccordionDetails>
          </Accordion>

          {/* Contactanos */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Contáctanos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {footerContent?.information?.map((info, index) => (
                <Box key={index} display="flex" alignItems="center" mb={2}>
                  <Button variant="contained" component="label" sx={{ mr: 1 }}>
                    Subir Imagen
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) =>
                        handleFileUpload(e, index, 'information', 'image')
                      }
                    />
                  </Button>
                  {info.image && (
                    <Box
                      component="img"
                      src={info.image}
                      alt="Imagen de Contáctanos"
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                  )}
                  <TextField
                    label="Texto"
                    value={info.text}
                    onChange={(e) =>
                      handleLinkChange('information', index, 'text', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <TextField
                    label="URL"
                    value={info.href}
                    onChange={(e) =>
                      handleLinkChange('information', index, 'href', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveLink('information', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={() => handleAddLink('information')}>
                Agregar Enlace
              </Button>
            </AccordionDetails>
          </Accordion>
          
          {/* Centro Ayuda 
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Centro Ayuda</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {footerContent?.centroAyuda?.map((item, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <TextField
                    label="Texto"
                    value={item.text}
                    onChange={(e) =>
                      handleLinkChange('centroAyuda', index, 'text', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <TextField
                    label="URL"
                    value={item.href}
                    onChange={(e) =>
                      handleLinkChange('centroAyuda', index, 'href', e.target.value)
                    }
                    sx={{ mr: 1 }}
                  />
                  <IconButton onClick={() => handleRemoveLink('centroAyuda', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={() => handleAddLink('centroAyuda')}>
                Agregar Enlace
              </Button>
            </AccordionDetails>
          </Accordion>
        */}

          {/* Redes Sociales */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Redes Sociales</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {footerContent?.socialLinks?.map((social, index) => (
                <Box
                  key={index}
                  component={Paper}
                  elevation={3}
                  sx={{ p: 2, mb: 3, borderRadius: 2 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="URL"
                        value={social.url}
                        onChange={(e) =>
                          handleSocialLinkChange(index, 'url', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Color de Fondo"
                        type="color"
                        value={social.bgColor}
                        onChange={(e) =>
                          handleSocialLinkChange(index, 'bgColor', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton
                        onClick={() => handleRemoveSocialLink(index)}
                        color="error"
                        sx={{ mt: 2 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" component="label">
                        Subir Imagen
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={(e) =>
                            handleFileUpload(e, index, 'socialLinks', 'icon')
                          }
                        />
                      </Button>
                      {social.icon && (
                        <Box
                          component="img"
                          src={social.icon}
                          alt="Ícono Red Social"
                          sx={{
                            width: 60,
                            height: 60,
                            mt: 2,
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={handleAddSocialLink} variant="contained">
                Agregar Red Social
              </Button>
            </AccordionDetails>
          </Accordion>



          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleCloseModal} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Guardar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Footer;
