// src/App.js
import React from 'react';
import LoginArea from '../components/Login/LoginArea';

const MarketTema1 = () => (
    <div className="w-screen min-h-screen css-selector" >
      <div className="pt-[5%] pb-10">
        
         <LoginArea />

      </div>
    </div>
);


export default MarketTema1;
