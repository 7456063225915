import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Chip,
  CircularProgress
} from '@mui/material';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {  INTEGRACIONES, PLUGINS , SECCIONES , initialState}  from '../../../../utils/configFeatures';


function FeatureToggleModal({ open, onClose, onSave, store , decompressFeatures}) {
  
  console.log("storeConfig decompressFeatures",decompressFeatures);
  console.log("storeConfig initialState",initialState); 
  const [loading, setLoading] = useState(false); // Agregamos el estado de carga
  // 1. Inicializamos el estado combinando todos los 'key' con sus valores por defecto


  const [features, setFeatures] = useState(decompressFeatures);

  // Manejador para actualizar los valores del estado
  const handleFeatureChange = (featureKey) => (event) => {
    setFeatures((prev) => ({
      ...prev,
      [featureKey]: event.target.checked,
    }));
  };

  // Al presionar "Guardar", enviamos los datos y cerramos
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await onSave?.(features,store);// Simula guardar los datos
    } finally {
      setLoading(false); // Desactivar indicador de carga
      onClose();
    }
  };

  // Componentes reutilizables para simplificar:
  const renderFeatureGrid = (dataArray) => (
    <Grid container spacing={2}>
      {dataArray.map((item) => (
        <Grid item xs={item.xs} sm={item.sm} md={item.md} key={item.key}>
          <div style={{
            padding: '16px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            height: '100%',
            boxSizing: 'border-box',
          }}>
            <FormControlLabel
              control={
                <Switch
                  checked={features[item.key]}
                  onChange={handleFeatureChange(item.key)}
                />
              }
              label={item.label}
            />
            {item.chips?.map((chip, idx) => (
              <Chip
                key={idx}
                label={chip.label}
                color={chip.color || 'default'}
                size="small"
                style={{ marginLeft: '8px', verticalAlign: 'middle' }}
              />
            ))}
            <br />
            <Typography variant="caption" style={{ color: '#555' }}>
              {item.description}
            </Typography>
            {item.requisitos && (
              <>
                <br />
                <Typography
                  variant="caption"
                  style={{ color: '#555', fontWeight: 'bold', marginTop: '8px' }}
                >
                  Requisitos:
                </Typography>
                <ul style={{ paddingLeft: '16px', marginTop: '4px', listStyleType: 'disc' }}>
                  {item.requisitos.map((req, rIdx) => (
                    <li key={rIdx}>{req}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography variant="h7">
            Configuración del sitio "{ store.nombre.toLowerCase() }"
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          {/* Accordion para Secciones */}
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Secciones del Sitio</b>
                <br />
                Personaliza tu sitio web seleccionando qué secciones deseas mostrar u ocultar.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFeatureGrid(SECCIONES)}
            </AccordionDetails>
          </Accordion>

          {/* Accordion para Plugins */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Plugins</b>
                <br />
                Funcionalidades adicionales para mejorar la experiencia del usuario.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFeatureGrid(PLUGINS)}
            </AccordionDetails>
          </Accordion>

          {/* Accordion para Integraciones */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Integraciones</b>
                <br />
                Conecta la plataforma con servicios externos para ampliar sus capacidades.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFeatureGrid(INTEGRACIONES)}
            </AccordionDetails>
          </Accordion>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="secondary" disabled={loading} >
            Cancelar
          </Button>

          
          <Button variant="contained" 
                  color="primary" 
                  type="submit"
                  disabled={loading} // Botón deshabilitado durante la carga
                  startIcon={loading && <CircularProgress size={20} />} // Indicador en el botón
          >
          {loading ? 'Guardando...' : 'Guardar Cambios'} 
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FeatureToggleModal;
