// src/hooks/useStoreName.js
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const useStoreName = () => {


  const urlReplace = process.env.REACT_APP_URL_REPLACE || '';
  const [storeName, setStoreName] = useState('');
  const [isAdminRoute, setIsAdminRoute] = useState(false);
  const { tienda = "" } = useParams();
  const location = useLocation();
  
  useEffect(() => {
    const path = location.pathname;
    
    // Determinar si es una ruta de administración
    const isAdmin = path.includes('/administrar') //sie sta en la ruta de admin validar si es su tienda y asi
    setIsAdminRoute(isAdmin);
    let hostname = window.location.hostname.toLowerCase() ;
    let subhostname = getHostPart(hostname,0);
    if (isAdmin) {
      // Si es ruta de administración, usar 'tienda' de los parámetros
      setStoreName(tienda.toLowerCase());
    } else {
      if (isSubdomain(hostname) && hostname.includes(urlReplace) ) {
        setStoreName(subhostname);
      } else {
        if (isSubdomain(hostname)){
           hostname = getHostPart(hostname,1);
           setStoreName(hostname);
        }else{
          setStoreName(getHostPart(hostname,0));
        }
      }
    }

    logBuildInfo(tienda.toLowerCase(),isAdmin,hostname,subhostname);
  }, [location]);
  

  const logBuildInfo = (storeName,isAdmin,hostname,subhostname) => {
      console.log(`
    **************************************************
    *                                                *
    *            🌟 Información del Build 🌟         *
    *                                                *
    **************************************************

    🚀 Aplicación            : ${process.env.REACT_APP_URL_REPLACE || 'N/A'}
    🚀 Tienda                : ${storeName || 'N/A'}
    🚀 Hostname              : ${hostname || 'N/A'}
    🚀 Admin                 : ${isAdmin || 'N/A'}
    🚀 Sub Dominio           : ${subhostname || 'N/A'}
    📅 Fecha del Build       : ${process.env.REACT_APP_BUILD_DATE || 'N/A'}
    🆔 ID del Build (SHA)    : ${process.env.REACT_APP_BUILD_ID || 'N/A'}
    🔢 ID del Build (Corto)  : ${process.env.REACT_APP_BUILD_ID_SHORT || 'N/A'}
    🌿 Nombre de la Rama     : ${process.env.REACT_APP_BRANCH_NAME || 'N/A'}
    👤 Autor del Commit      : ${process.env.REACT_APP_AUTHOR_NAME || 'N/A'}
    📝 Mensaje del Commit    : ${process.env.REACT_APP_COMMIT_MESSAGE || 'N/A'}
    🔗 ID del Job            : ${process.env.REACT_APP_RUN_ID || 'N/A'}
    📦 Nombre del Build      : ${process.env.REACT_APP_BUILD_NAME || 'N/A'}
    
    **************************************************
    `);
  };


  function isSubdomain(hostname) {
    // Divide el hostname por los puntos
    const parts = hostname.split('.');
    // Si tiene más de 2 partes, probablemente sea un subdominio
    // Ejemplo: "sub.mivisualization.com" -> ['sub', 'mivisualization', 'com']
    //          "mivisualization.com" -> ['mivisualization', 'com']
    if (parts.length > 2) {
      return true; // Es un subdominio
    }
    // Para dominios como "co.uk", "gov.uk", puedes añadir excepciones
    const knownSecondLevelDomains = ['co.uk', 'gov.uk', 'edu.au'];
    const domain = parts.slice(-2).join('.'); // Últimos 2 niveles
    if (knownSecondLevelDomains.includes(domain)) {
      return parts.length > 3; // Si hay más niveles, es un subdominio
    }
    return false; // Es un dominio raíz
  }

  function getHostPart(hostname,int) {
    const parts = hostname.split('.');
    if (parts.length > 1) {
      return parts[int]; // Retorna la palabra más a la izquierda
    }
    return null; // No es un subdominio
  }

  return { storeName, isAdminRoute };
};

export default useStoreName;
