import React, { useState, useEffect } from 'react';
import BlogAddButon from "./BlogAddButton"
import BlogEditButton from "./BlogEditButton"
import BlogDeleteButton from "./BlogDeleteButton"
import BlogTiktokShare from "./BlogTiktokShare"
import BlogModal from "./BlogModal"
import BlogSkeleton from "./BlogSkeleton"
import { auth, db  } from '../../../firebaseConfig';
import Slider from "react-slick";
import { doc, updateDoc } from 'firebase/firestore';
import {deleteDocument} from '../../../utils/firebaseUtils';
import { Link } from 'react-router-dom';
import { saveToFirestore, uploadFile } from '../../../utils/firebaseUtils';
import {formatDate} from '../../../utils/formatDate'




  const BlogDesing = ({ posts, storeName, setPosts,isAdminRoute ,isTikTokEnabled}) => {
    
    const { generateSlug } = require('../../Producto/utils/slugGenerator');
    const [user, setUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [date, setDate] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [image, setImage] = useState('');
    const [tag, setTag] = useState([]);
    const [loading, setLoading] = useState(false);
    const [postToEdit, setPostEdit] = useState(null);

    //console.log('storeName1',storeName)
    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);



  

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            let imageUrl = image;
            
            if (image instanceof File) {
                const uploadPath = `${storeName}/blog/images`;
                //console.log('Subiendo archivo:', image, 'a la ruta:', `${storeName}/blog/images`);
                imageUrl = await uploadFile(image, uploadPath);
            }
            
            const updatedPost = {
                date,
                title,
                description,
                shortDescription,
                image: imageUrl,
                tag: Array.isArray(tag) ? tag : [],
            };
    
            if (postToEdit) {
                const collectionPath = `${storeName}/blog/entry`;
                const docRef = doc(db, collectionPath, postToEdit.id);
                await updateDoc(docRef, updatedPost);
    
                setPosts((prevPosts) =>
                    prevPosts.map((post) =>
                        post.id === postToEdit.id ? { ...post, ...updatedPost } : post
                    )
                );
                //console.log(`Post con ID ${postToEdit.id} actualizado.`);
            } else {
                const collectionPath = `${storeName}/blog/entry`;
                const newDocumentId = `${Date.now()}`;
                const savedPost = await saveToFirestore(collectionPath, newDocumentId, updatedPost);
                setPosts([...posts, { id: newDocumentId, ...updatedPost }]);
            }
    
            setTitle('');
            setDescription('');
            setImage('');
            setTag([]);
            setDate('');
            setPostEdit(null);
            closeModal();
        } catch (error) {
            console.error("Error al guardar el post:", error.message);
        } finally {
            setLoading(false);
        }
    };
    

    const handleDelete = async (postId) => {
        if (window.confirm("¿Estás seguro de que deseas eliminar este post?")) {
            try {
                setLoading(true);
                const collectionPath = `${storeName}/blog/entry`;
                await deleteDocument(collectionPath, postId);
                setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
                //console.log(`Post con ID ${postId} eliminado.`);
            } catch (error) {
                console.error("Error al eliminar el post:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleEdit = async (postId) => {
        const postToEdit = posts.find((post) => post.id === postId);
    
        if (postToEdit) {
            setDate(postToEdit.date);
            setTitle(postToEdit.title);
            setDescription(postToEdit.description);
            setShortDescription(postToEdit.shortDescription);
            setImage(postToEdit.image);
            setTag(postToEdit.tag || []);
            setPostEdit(postToEdit);
            setIsModalOpen(true);
        } else {
            console.error("Post no encontrado para editar");
        }
    };




    

    const settings = {
        dots: posts.length > 3,
        infinite: false,
        speed: 500,
        slidesToShow: Math.min(posts.length, 3),
        slidesToScroll: Math.min(posts.length, 3),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(posts.length, 2),
                    slidesToScroll: Math.min(posts.length, 2),
                    dots: true,
                    infinite: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: Math.min(posts.length, 1),
                    slidesToScroll: 1,
                    dots: true,
                    infinite: false,
                },
            },
        ],
    };


    return loading ? (
        <BlogSkeleton />
    ) : (
        <div style={{ position: 'relative' }}>
        
            <div className="text-center">
                <h1 className="text-2xl font-semibold pt-4 md:py-5"  style={{ color: '#255ce0', fontSize: '35px', fontWeight: '800' }}>Blog</h1>
            </div>

            {posts.length === 0 ? (
                <p className="text-center py-5 text-darkgrey text-[14px]">No hay contenido para mostrar</p>
            ) : (
                <section className="py-10">
                    <div className="container mx-auto">
                        <div className="gap-6">
                            <Slider {...settings}>
                                {posts.map((post) => {
                                    
                                    const slug = generateSlug(post.title);
                                    const productLink = post.id
                                      ? isAdminRoute
                                        ? `/${storeName}/administrar/blog/${post.id}/${slug}`
                                        : `/blog/${post.id}/${slug}`
                                      : '#';

                                    return (
                                    <div key={post.id} className="px-2">
                                        <div className="single-blog-post bg-white shadow-md rounded-lg overflow-hidden relative">
                                            {user &&
                                                <div className="buttons-overlay absolute top-3 right-3 flex flex-col gap-2 z-10">
                                                    <BlogEditButton onClick={() => handleEdit(post.id)} />
                                                    <BlogDeleteButton onClick={() => handleDelete(post.id)} />
                                                    
                                                                                    
                                                    {isTikTokEnabled && (
                                                        <BlogTiktokShare
                                                            title={post.title}
                                                            shortDescription={post.shortDescription}
                                                            image={post.image}
                                                            storeName={storeName}
                                                        />
                                                    )}

                                                    
                                                   
                                                </div>
                                            }

                                                            
                                            

                                            <div className="blog-image relative">
                                                <a href={post.link || '#'}>
                                                    <img
                                                        src={post.image || "assets/img/default.jpg"}
                                                        alt="Blog"
                                                        className="w-full h-[200px] md:h-[300px] object-cover"
                                                    />
                                                </a>
                                                <div className="post-tag absolute top-3 left-3 bg-black text-white px-3 py-1 text-[14px] capitalize h-[35px] flex items-center">
                                                    <span>{(post.tag && post.tag.length > 0 ? post.tag[0] : "General")}</span>
                                                </div>
                                            </div>
                                            <div className="p-4 flex flex-col items-start text-left">
                                                <span className="date text-gray-500 text-[13px]">{formatDate(post.date)}</span>
                                                <h3 className="mt-2 text-[18px] font-bold h-[50px]">
                                                    <Link to={productLink}
                                                        state={{
                                                            storeName,
                                                            date: post.date,
                                                            title: post.title,
                                                            image: post.image,
                                                            description: post.description,
                                                            shortDescription:post.shortDescription,
                                                            tag: post.tag
                                                        }}
                                                        className="hover:text-blue-500 capitalize"
                                                        style={{
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient: "vertical",
                                                            overflow:"hidden",
                                                        }}
                                                    >
                                                        {post.title || "text default"}
                                                    </Link>
                                                </h3>
                                                <p
                                                    className="text-[14px] text-gray-600 mt-2 overflow-hidden h-[50px]"
                                                    style={{
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: "vertical",
                                                        overflow:"hidden",
                                                    }}
                                                >
                                                    {post.shortDescription || "text default"}
                                                </p>
                                                <Link 
                                                    to={productLink}
                                                    state={{ 
                                                        storeName, 
                                                        date: post.date, 
                                                        title: post.title, 
                                                        image: post.image, 
                                                        description: post.description,
                                                        shortDescription:post.shortDescription,
                                                        tag: post.tag 
                                                    }} 
                                                    className="read-more-btn text-blue-500 mt-4 inline-block text-[14px]"
                                                >
                                                    Leer Más
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                </section>
            )}
            
            
            {user && <BlogAddButon onClick={openModal} />}
            

            <BlogModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onSubmit={handleSubmit}
                date={date}
                setDate={setDate}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
                shortDescription={shortDescription}
                setShortDescription={setShortDescription}
                image={image}
                setImage={setImage}
                tag={tag}
                setTag={setTag}
            />
        </div>
    );
};

export default BlogDesing;