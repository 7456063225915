import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Alert,Button, Tooltip, IconButton } from '@mui/material';
import ItemTiendaPreview from './components/ItemTiendaPreview';
import useStore from '../../hooks/useStore'; 
import AddStoreForm from './components/AddStoreForm';
import AddStoreButton from './components/AddStoreButton';
import DeleteStoreButton from './components/DeleteStoreButton';
import AdminStoreButton from './components/AdminStoreButton';
import SectionStoreButton from './components/configuracion/SectionStoreButton';
import LogOutButton from './components/LogOutButton';
import { signOut } from 'firebase/auth'; 
import { auth } from '../../firebaseConfig'; 
import compress from '../../utils/compress'; 
import decompress from '../../utils/decompress'; 


const TiendaManager = () => {

  const { registerStore, getStores, loading, error,authenticatedUser , deleteStore , registerStoreConfig } = useStore(); 
  const [ userStores, setUserStores] = useState([]); 
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchStores = async () => {
      if (authenticatedUser) {
        const stores = await getStores();     
        
        const formattedStores = stores.map((store, index) => ({
          id: index + 1, 
          nombre: store , 
          imagen: 'assets2/img/tiendamanager/item-tienda-fondo.webp', 
        }));
        setUserStores(formattedStores);
      };
    };
    fetchStores();
  }, [authenticatedUser]);


  const handleTiendaClick = (store) => {
    const dominioBase = process.env.REACT_APP_URL_REPLACE;
    const dominioBaseSSL = process.env.REACT_APP_URL_SSL;
    const url = `${dominioBaseSSL}://${store.nombre}.${dominioBase}`; 
    window.open(url, '_blank'); 
  };

  const handleAdminClick = (store) => {
    const dominioBase = process.env.REACT_APP_URL_REPLACE; 
    const dominioBaseSSL = process.env.REACT_APP_URL_SSL;
    const url = `${dominioBaseSSL}://${dominioBase}/${store.nombre}/administrar`; 
    window.open(url, '_blank'); 
  };
  

  const handleFeatureSaveClick = async (features, store) => {
    try {
      const compressedFeatures = compress({ features });
      await registerStoreConfig(store, compressedFeatures);
      console.log("Store:", store);
      console.log("Features originales:", features);
      console.log("Features comprimidas:", compressedFeatures);
    } catch (error) {
      console.error("Error en handleFeatureSaveClick:", error);
    }
  };

  const handleAddStore = async (newStoreName) => {
    const isDuplicate = userStores.some((store) => store.nombre.toLowerCase() === newStoreName.toLowerCase());
    
    if (isDuplicate) {
      setSnackbarMessage('Este nombre ya está registrado');
      setOpenSnackbar(true);
      return;
    }
    const newStore = {
      id: userStores.length + 1, 
      nombre: newStoreName,
      imagen: 'assets/img/category-products-img2.jpg', 
    };

    const result = await registerStore({ title: newStoreName });
    if (!result.success) {
      console.error('Error al registrar la tienda:', result.error || 'Error desconocido');
    }
    
    setUserStores((prevStores) => [...prevStores, newStore]); 
  };

  const handleDeleteStore = async (newStoreName) => {
    const result = await deleteStore(newStoreName);
    if (!result.success) {
      console.error('Error al eliminar la tienda:', result.error || 'Error desconocido');
    }
    setUserStores((prevStores) => prevStores.filter((store) => store.nombre !== newStoreName));
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('Sesión cerrada');
      window.location.href = '/'; 
    } catch (error) {
      console.error('Error al cerrar sesión:', error.message);
    }
  };


  const handleOpenForm = () => setIsFormOpen(true);
  const handleCloseForm = () => setIsFormOpen(false);
  const handleCloseSnackbar = () => setOpenSnackbar(false);

  return (

    <Box>
      <div className="container pt-3">
      <div className="section-title flex flex-row md:items-center justify-between bg-white/80 rounded-lg shadow-lg backdrop-blur-sm   ">
        <h2><span className="dot text-white"></span> Gestionar Tiendas</h2>
        <div style={{ display: 'flex', gap: '10px',alignItems:'center' }} sx={{
          justifyContent: {
            xs: 'center', 
            sm: 'center',
            md: 'flex-end', }
        }}>
          <AddStoreButton onClick={handleOpenForm} />
          <LogOutButton onClick={handleLogout}/>
        </div>
      </div>

      
      
      <AddStoreForm
        open={isFormOpen}
        onClose={handleCloseForm}
        onSave={handleAddStore}
      />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          },
          gap: 4,
          overflowX: 'hidden',
        }}
        
      >
        {userStores.map((store) => (
          <div key={store.id} style={{ position: 'relative' }}  >
            <ItemTiendaPreview
              key={store.id}
              imagen={store.imagen}
              nombre={store.nombre.toLowerCase()}
              onTiendaClick={() => handleTiendaClick(store)}
              onAdminClick={() => handleTiendaClick(store)}
            />
            <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 10 }}>
              <DeleteStoreButton onDelete={() => handleDeleteStore(store.nombre)} />
            </div>
            <div style={{ position: 'absolute', top: '10px', right: '55px', zIndex: 10 }}>
              <AdminStoreButton onAdminClick={() => handleAdminClick(store)} />
            </div>

            <div style={{ position: 'absolute', top: '10px', right: '55px', zIndex: 10 }}>
              <SectionStoreButton onSave={handleFeatureSaveClick}  store={store}/>
            </div>

            <div style={{ position: 'absolute', top: '10px', right: '100px', zIndex: 10 }}>
              <AdminStoreButton onAdminClick={() => handleAdminClick(store)} />
            </div>
            
            

            
          </div>
        ))}
      </Box>

      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TiendaManager;

