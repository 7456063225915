import React from 'react';


const Ui3 = ({ id, titulo, descripcion, color, porcentaje,colorStart,colorEnd,angle }) => {
  return (

    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 flex relative">
      
        <div
          className="w-12 h-12 inline-flex items-center justify-center rounded-full text-white mb-4 p-2 flex-shrink-0"
          style={{
            background: `linear-gradient(${angle}deg, ${colorStart}, ${colorEnd})`}} 
        >
          <p className='text-white font-semibold text-[18px]' style={{
            color: `${color}`,
          }} >{porcentaje}%</p>

        </div>

        <div className="flex-grow pl-6 text-left">
          <h2 className="text-gray-900 text-lg title-font font-semibold mb-2">{titulo}</h2>

          <p className="leading-relaxed text-base text-gray-400">{descripcion}</p>

          <a href="#" className="mt-3 text-indigo-400 inline-flex items-center hover:underline">
            Learn More
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Ui3;
