/*import React, { useState, useEffect } from 'react';
import { Tooltip, Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const GaleriaEditButton = ({ onClick }) => {
  
  return (
    <>
        <Tooltip title="Editar Galeria" arrow>
            <Fab color="primary"
                aria-label="edit"
                onClick={onClick}
                style={{
                    position: 'absolute',
                    bottom: '1rem',
                    right: '1rem',
                    backgroundColor: 'white',
                }}
            >
            <EditIcon style={{ color: '#0000008a' }}/>
            </Fab>
        </Tooltip>
    </>
  );
};

export default GaleriaEditButton;*/


import React from 'react';
import { Button } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const GaleriaEditButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<DesignServicesIcon />}
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '2rem',
        right: '-5rem', // Posición inicial fuera de vista
        transition: 'right 0.3s ease', // Transición suave al modificar la propiedad "right"
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 'none',
        zIndex: 2,
        background: 'linear-gradient(90deg, #a000b8, #5724ff 20%, #003efa)', // Aplicar gradiente
        color: 'white', // Asegurar que el texto sea visible
        '&:hover': {
          right: '0rem', // Nueva posición al hacer hover
          background: 'linear-gradient(90deg, #a000b8, #5724ff 50%, #003efa)', // Reaplica gradiente en hover
        },
      }}
    >
      Galeria
    </Button>
  );
};

export default GaleriaEditButton;
