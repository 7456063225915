import React from 'react';


const Ui1 = ({ id, titulo, descripcion, color, porcentaje,colorStart,colorEnd,angle }) => {
  return (  
    
    <div
      className="rounded-lg flex items-start flex-col gap-3 transition-transform transition-shadow duration-300 hover:shadow-card hover:-translate-y-2"
      style={{
        padding: '40px', 
        borderRadius: '20px',
      }}  
    >
      <div className="flex justify-between w-full">
        <div
          className="flex justify-center items-center bg-[#004cd3]"
          style={{
            padding: '15px',
            width: '60px',
            height: '60px',
            borderRadius: '12px',
            background: `linear-gradient(${angle}deg, ${colorStart}, ${colorEnd})`,
          }}
        >
           <p className='font-semibold text-white text-[24px]' style={{color:`${angle}`}}>{porcentaje}%</p>
        </div>


      </div>

    
      <div className='flex flex-col gap-3'>
        <p className="text-left font-bold text-xl tex-[20px] text-black">{titulo}</p>
        <p className="text-left font-normal text-base text-black max-h-[100px] min-h-[100px] line-clamp-4">
          {descripcion}
        </p>
      </div>
    </div>
  );
};

export default Ui1;
