import React, { useState, useEffect } from "react";
import NavbarPreview from './components/NavbarPreview';
import NavbarEditButton from "./components/NavbarEditButton";
import NavbarForm from "./components/NavbarForm";
import decompressNavbar from "./utils/decompressNavbar";
import { saveToFirestore } from '../../utils/firebaseUtils';
import { auth } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

const NavbarManager = ({ storeName, compressedLogo, compressedNavbar }) => {
  
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [navbarData, setNavbarData] = useState({ headerType: "diseño1", items: [] }); // Inicializamos con headerType y items
  const [dialogOpen, setDialogOpen] = useState(false);
  const [user, setUser] = useState(null);


  useEffect(() => {
    if (compressedNavbar) {
      const decompressedData = decompressNavbar(compressedNavbar);
      setNavbarData(decompressedData);
    }
  }, [compressedNavbar]);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);


  const handleSaveNavbar = async (compressedData) => {
    const navbarData = compressedData;
    await saveToFirestore(storeName, 'config', { navbarData });
    const decompressedData = decompressNavbar(compressedData);
    setNavbarData(decompressedData);
  };

  return (
    <>

    <div style={{ position: 'relative', overflow: 'hidden' }}>




      {/* Vista del Navbar */}
      <NavbarPreview
        storeName={storeName}
        compressedLogo={compressedLogo}
        items={navbarData.items} 
        headerType={navbarData.headerType} 
        background={navbarData.headerBackgroundColor}
        isMenuOpen={isMenuOpen} 
        toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
      />

      {/* Botón para editar el Navbar (solo visible para usuarios autenticados) */}

      {user && <NavbarEditButton onClick={() => setDialogOpen(true)} />}

      {/* Formulario para editar el Navbar */}
      <NavbarForm
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSave={handleSaveNavbar}
        currentNavbar={navbarData}
      />

</div>
    </>
  );
};

export default NavbarManager;


{/*

import React, { useState } from "react";
import LogoManager from '../Logo/LogoManager';

const NavbarManager = ({ storeName , compressedLogo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white border-b border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl mx-auto flex items-center justify-between p-3">
      
        <a
          href="#"
          className="flex items-center space-x-2 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <LogoManager storeName={storeName} compressedLogo={compressedLogo} />
          <span>Tailwind</span>
        </a>

       
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="md:hidden p-2 rounded text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-label="Toggle menu"
        >
          {isMenuOpen ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </button>

        
        <div className="hidden md:flex md:items-center md:w-auto">
          <ul className="flex flex-row items-center space-x-6">
            <li>
              <a
                href="#"
                className="block py-2 px-4 text-lg text-black hover:text-blue-500 dark:text-white dark:hover:text-blue-400"
              >
                Beneficios
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-4 text-lg text-black hover:text-blue-500 dark:text-white dark:hover:text-blue-400"
              >
                Casos de Éxito
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-4 text-lg text-black hover:text-blue-500 dark:text-white dark:hover:text-blue-400"
              >
                Clientes
              </a>
            </li>
            <li>
                <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                  Button
                </button>
            </li>
          </ul>

        </div>
      </div>

    
      <div
        className={`${
          isMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
        } overflow-hidden transition-all duration-500 ease-in-out md:hidden w-full bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-700`}
      >
        <ul className="flex flex-col space-y-4 p-4">
          <li>
            <a
              href="#"
              className="block py-2 px-4 text-lg text-black hover:text-blue-500 dark:text-white dark:hover:text-blue-400"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="#"
              className="block py-2 px-4 text-lg text-black hover:text-blue-500 dark:text-white dark:hover:text-blue-400"
            >
              About
            </a>
          </li>
          <li>
            <a
              href="#"
              className="block py-2 px-4 text-lg text-black hover:text-blue-500 dark:text-white dark:hover:text-blue-400"
            >
              Services
            </a>
          </li>
          <li>
            <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                  Button
                </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavbarManager;   */}


