import React from "react";

const Ui2 = ({id,title,description,button,buttonColor,url1,reportUrl,button2,buttonColor2,url2,fontColor1,fontColor2}) => {

  return (

    <section className="text-gray-600 body-font  w-[90%] md:w-[80%] mx-auto  mb-4 ">
      <div className="container gap-[20px] mx-auto flex items-center pt-4 md:py-4 justify-center flex-col">
        <iframe
            title="Power BI Report"
            src={reportUrl}
           
            className="h-[200px] md:h-[400px] md:w-[50%] w-full m-auto "
            style={{ border: "none" }}
            allowFullScreen
            />
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font  text-2xl	 md:text-3xl mb-4 font-semibold text-gray-900">
            {title}
          </h1>
          <p className="mb-8 leading-relaxed text-sm md:text-base text-justify md:text-center">
            {description}
          </p>
          <div className="flex justify-center gap-2">
          <button className="inline-flex  bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
               style={{ backgroundColor: buttonColor, color: fontColor1 }}
                onClick={() => window.location.href = url1}
                >
              {button}
            </button>

            <button
              className="inline-flex  bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              style={{ backgroundColor: buttonColor2,color: fontColor2  }}
              onClick={() => window.location.href = url2}
            >
              {button2}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ui2;
