import React from 'react';
import { useNavigate } from 'react-router-dom';

const FooterWidget = ({
  title,
  content,
  links,
  style,
  icono,
  storeName,
  isAdminRoute,
  footerTextColor,
  footerTextoTodo,
  diseño2,
}) => {
  const navigate = useNavigate();

  const handleLinkClick = (e, href, text) => {
    e.preventDefault(); // Previene la acción por defecto del enlace

    if (text.toLowerCase().includes("terms and conditions")) {
      const route = isAdminRoute
        ? `/${storeName}/administrar/politicasPrivacidad`
        : `/politicasPrivacidad`;
      navigate(route);
    } else {
      window.location.href = href;
    }
  };

  return (

    <div  className={`col-lg-3 col-md-6 col-sm-6 ${ diseño2 ? 'flex justify-center' : '' }`} 
          style={{ textAlign: 'left', ...style }}>
      
      <div className="single-footer-widget">
        
        {title && <h3 style={{ color: footerTextColor }} className='font-text-lg !normal-case font-bold before:!bg-transparent'>{title}</h3>}
        {content}

        {links && (
          <ul className={title ? 'quick-links' : 'Centro de Ayuda'}>
            {links.map((link, index) => (
              <li key={index}>
                <div className="flex gap-2 items-center" style={{ color: footerTextColor }}>

                  {title === "Contáctenos" && (
                    
                    <div className="flex items-center justify-center w-8 h-8  rounded-full">
                          
                          <img
                            src={link.image}
                            alt="payment"
                            className='max-w-[20px] max-h-[20px]'
                          />

                    </div>
                    
                  )}

                  <a
                    href={link.href}
                    onClick={(e) => handleLinkClick(e, link.href, link.text)}
                    style={{ color: footerTextoTodo }}
                    className='max-w-[230px] text-[15px]'
                  >
                    {link.text}
                  </a>

                </div>
              </li>
            ))}
          </ul>
        )}

      </div>
    </div>
  );
};

export default FooterWidget;
