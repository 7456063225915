import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
//import BeneficiosForm from './components/BeneficiosForm';
import BeneficiosAddButton from './components/button/BeneficiosAddButton';
import BeneficiosPreview from './components/BeneficiosPreview';
import decompressBeneficios from './utils/decompressBeneficios';
import { saveToFirestore } from '../../utils/firebaseUtils';
import { doc, getDoc } from 'firebase/firestore'; 
import { db ,auth} from '../../firebaseConfig'; 
import LZString from 'lz-string';
import BeneficiosForm from './components/form/BeneficiosForm'; 
import Ui4 from './components/ui/Ui4'; 


const BeneficiosManager = ({ storeName , compressedBeneficios }) => {
  

  const [beneficiosArray, setBeneficiosArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 


  useEffect(() => {
    const fetchBeneficios = async () => {
      if (!compressedBeneficios) {
        console.error('No se proporcionaron datos comprimidos para beneficios.');
        setError('Datos de beneficios no disponibles.');
        setLoading(false);
        return;
      }
      try {
        const decompressedData = decompressBeneficios(compressedBeneficios);
        if (!decompressedData) {
          throw new Error('Descompresión fallida.');
        }
        // Suponiendo que decompressedData es una cadena JSON válida
        /*const beneficiosConIds = decompressedData.beneficios.map((beneficio, index) => ({
          ...beneficio,
          id: beneficio.id || index + 1,
        }));*/
        setBeneficiosArray(decompressedData);
      } catch (err) {
        console.error('Error al procesar los beneficios:', err);
        setError('Error al cargar los beneficios.');
      } finally {
        setLoading(false);
      }
    };
    fetchBeneficios();
  }, [storeName]);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBeneficio, setNewBeneficio] = useState({
    titulo: '',
    descripcion: '',
    porcentaje: 20, // Definir valor por defecto
    color: '#ffffff',
    colorStart: '#d9ddf1',
    colorEnd: 'rgba(233, 0, 0, 0%)',
    angle: '180deg', // Agregar angle al nuevo beneficio
  });
  const [beneficioToEdit, setBeneficioToEdit] = useState(null); 
 

  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);


  const handleSaveBeneficio = async (compressedBeneficioData) => {
    try {
      const decompressedData = decompressBeneficios(compressedBeneficioData);
      setBeneficiosArray(decompressedData);
      await saveToFirestore(storeName, 'config', { beneficios: compressedBeneficioData });
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al guardar el beneficio:', error);
    }
  };

  
  const selectedBeneficio = beneficiosArray.disenoSeleccionado === 'ui4';
  

  return (
    <Box sx={{ position: 'relative' }}>
      
      {/*
      {storeName === 'mivisualization' && (
          <div className=' flex items-end' style={{position:'absolute', top:'-0rem'}}>
              <div className='bg-[#26B7FF] h-[65px] rounded-tr-[15px] w-[55px]'></div>
              <div className='bg-[#5F7EFF] h-[45px] w-[45px] rounded-tr-[15px] z-[1]' style={{position:'relative'}}></div>
          </div>
      )}
*/}

      <BeneficiosPreview 
        beneficiosArray={beneficiosArray}
        user={user}
      />

      {selectedBeneficio &&  storeName === 'mivisualization' && (
          
          <div className='flex items-end  !bottom-[-5rem]   lg:!bottom-[-8rem]' style={{position:'absolute', top:'16.6rem'}}>
              <div className='bg-[#26B7FF] h-[115px] w-[55px] md:h-[175px]  md:w-[135px] rounded-tr-[15px] '></div>
              <div className='bg-[#5F7EFF] !h-[70px] md:!h-[60px] w-[45px] xl:!h-[95px]  md:w-[130px] rounded-tr-[15px] lg:z-[1]' style={{position:'relative', right:'5px'}}></div>
          </div>

      )}

      


      {user && (
        <BeneficiosAddButton onClick={() => setIsModalOpen(true)} />
      )}

      
      <BeneficiosForm
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveBeneficio}
        initialBeneficios={beneficiosArray}
      />
    </Box>
  );
};

export default BeneficiosManager;
