import React from 'react';
import Ui1 from './Ui1';        
import Ui2 from './Ui2';
import Ui3 from './Ui3';
import Ui4 from './Ui4';


import UiDefault from './UiDefault';

const CardBeneficio = ({
  designVariant,
  id,
  titulo,
  descripcion,
  porcentaje,
  color,
  colorStart,
  colorEnd,
  angle,
  colorTexto,
}) => {

  switch (designVariant) {
    case 'ui1':
      return (
        <Ui1
          id={id}
          titulo={titulo}
          descripcion={descripcion}
          porcentaje={porcentaje}
          color={color}
          colorStart={colorStart}
          colorEnd={colorEnd}
          angle={angle}
        />
      );
    case 'ui2':
      return (
        <Ui2
          id={id}
          titulo={titulo}
          description={descripcion}
          porcentaje={porcentaje}
          color={color}
          colorStart={colorStart}
          colorEnd={colorEnd}
          angle={angle}
        />
      );
    case 'ui3':
      return (
        <Ui3
          id={id}
          titulo={titulo}
          descripcion={descripcion}
          porcentaje={porcentaje}
          color={color}
          colorStart={colorStart}
          colorEnd={colorEnd}
          angle={angle}
        />
      );
      case 'ui4':
        return (

          

              <Ui4
                id={id}
                titulo={titulo}
                descripcion={descripcion}
                colorTexto={colorTexto}
              />

          
        );
    default:
      // Fallback a un diseño por defecto
      return (
        <UiDefault
          id={id}
          titulo={titulo}
          descripcion={descripcion}
          porcentaje={porcentaje}
          color={color}
          colorStart={colorStart || ''}
          colorEnd={colorEnd || ''}
          angle={angle || '180'}
        />
      );
  }
};

export default CardBeneficio;
