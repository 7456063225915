
import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { doc, getDocFromCache, getDocFromServer } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useParams } from 'react-router-dom';
import LZString from 'lz-string';
import NavbarManager from '../components/Navbar/NavbarManager';
import Footer from '../components/Footer/components/Footer';
import SplashScreenManager from '../components/SplashScreen/SplashScreenManager';
import DetalleProductoManager from '../components/DetalleProducto/DetalleProductoManager';
import useStoreName from '../hooks/useStoreName';
import { getDocumentById } from '../utils/firebaseUtils';

const Producto = () => {
  const { storeName } = useStoreName();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [producto, setProducto] = useState(null);
  const [storeConfig, setStoreConfig] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      if (!storeName || !id) return;

      try {
        // Fetch storeConfig
        const fetchStoreConfig = async () => {
          const storeDocRef = doc(db, storeName, 'config');
          let storeDoc;
          try {
            storeDoc = await getDocFromCache(storeDocRef);
          } catch {
            storeDoc = await getDocFromServer(storeDocRef);
          }

          if (storeDoc.exists()) {
            setStoreConfig(storeDoc.data());
          } else {
            throw new Error('No se encontró la configuración de la tienda.');
          }
        };

        // Fetch producto
        const fetchProducto = async () => {
          const collectionPath = `${storeName}/inventario/productos`;
          const productData = await getDocumentById(collectionPath, id);
          if (productData) {
            let additionalData = {};
            if (productData.data) {
              const decompressedData = LZString.decompressFromBase64(productData.data);
              additionalData = JSON.parse(decompressedData);
            }
            setProducto({
              id: productData.id,
              name: productData.name,
              productType: productData.productType,
              ...additionalData,
            });
          } else {
            throw new Error('Producto no encontrado');
          }
        };

        // Fetch both in parallel
        await Promise.all([fetchStoreConfig(), fetchProducto()]);
      } catch (err) {
        console.error('Error al cargar los datos:', err);
        setError(`Error al obtener los datos: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [storeName, id]);

  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <div>
      <div className={`splash-screen ${loading ? 'splash-screen--hidden' : 'splash-screen--visible'}`}>
        {storeConfig && (
          <>
            <NavbarManager
              storeName={storeName}
              compressedLogo={storeConfig.logoData}
              compressedNavbar={storeConfig.navbarData}
            />
            {producto && (
              <DetalleProductoManager storeName={storeName} producto={producto} />
            )}
            <Footer storeName={storeName} compressedFooter={storeConfig.footer} />
          </>
        )}
      </div>
      <div className={`splash-screen ${loading ? 'splash-screen--visible' : 'splash-screen--hidden'}`}>
        <SplashScreenManager />
      </div>
    </div>
  );
};

export default Producto;
