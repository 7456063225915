import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Tooltip, Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = ({
  isAdminRoute = false,
  position = { x: 0, y: 0 },
  onDragStop,
  onClick,
}) => {
  const [controlledPos, setControlledPos] = useState(position);

  // Sincroniza el estado interno con la posición que viene de props
  useEffect(() => {
    setControlledPos(position);
  }, [position]);

  const handleStop = (e, data) => {
    const newPos = { x: data.x, y: data.y };
    setControlledPos(newPos);
    if (onDragStop) {
      onDragStop(newPos);
    }
  };

  return (
    /**
     * 1) Un contenedor 'fixed' abarca toda la ventana, así el botón
     *    no se mueve al hacer scroll ni genera espacio extra.
     * 2) pointerEvents: 'none' evita bloquear otros clics en la página.
     */
    
      <Draggable
        disabled={!isAdminRoute}
        position={controlledPos}
        onStop={handleStop}
        bounds="parent"  // restringe arrastre a los bordes de este contenedor
      >
        {/**
         * pointerEvents: 'auto' aquí para que el botón
         * sí reciba clic/drag, pero sin bloquear el resto.
         */}
        <div style={{ pointerEvents: 'auto',position: 'absolute',zIndex: 999999, }}>
          <Tooltip title="WhatsApp" arrow>
            <Fab
              color="primary"
              aria-label="whatsapp"
              onClick={onClick}
              sx={{
                backgroundColor: '#25D366',
                color: 'white',
                // Quitar position: 'absolute' o 'fixed' en el Fab,
                // lo maneja Draggable con "transform: translate(...)"
              }}
            >
              <WhatsAppIcon sx={{ fontSize: '30px' }} />
            </Fab>
          </Tooltip>
        </div>
      </Draggable>
    
  );
};

export default WhatsAppButton;
