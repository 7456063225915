import React from 'react';

const CardServicio1 = ({ titulo, descripcion, color, icono, textoEnlace, href, hasLink }) => {
  return (
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      <div className="p-4 flex relative">
        
        {/* Contenido de la Tarjeta */}
        <div
          className="w-12 h-12 inline-flex items-center justify-center rounded-full text-white mb-4 p-2 flex-shrink-0"
          style={{
            backgroundColor: color || '#444',
          }}
        >
          <img
            src={icono}
            alt={`${titulo} icon`}
            className="object-contain w-auto h-7"
          />
        </div>
        

        <div className="flex-grow pl-6 text-left mb-6 md:mb-0">
          <h2 className="text-gray-900 text-lg title-font font-semibold mb-2">{titulo}</h2>

          <p className="leading-relaxed text-base text-gray-400">{descripcion}</p>
          
          {hasLink && (
            <a href={href} className="text-indigo-400 inline-flex items-center hover:underline">
              {textoEnlace}
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardServicio1;