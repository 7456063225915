import React from "react";
import LogoManager from '../../Logo/LogoManager';

const NavbarPreview = ({ storeName, compressedLogo, items, isMenuOpen, toggleMenu, headerType, background }) => {
  // Extraer el fondo del navbar de los items si está definido como tipo 'background'
 

  return (
    <>
    
      {headerType === "diseño1" && (
        <header
          className="text-gray-600 body-font"
          style={{ backgroundColor: background }} 
        >
          <div className="container mx-auto flex flex-wrap px-5 py-3 flex-col md:flex-row items-center">
            <a
              href="#"
              className="flex title-font font-medium items-center text-gray-900"
            >
              <LogoManager storeName={storeName} compressedLogo={compressedLogo} />
            </a>

            <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
              {items
                .filter((item) => item.type !== "button")
                .map((item, index) => (
                  <a key={index} href={item.href} className="mr-5 hover:text-gray-900">
                    {item.label}
                  </a>
                ))}
            </nav>
          </div>
        </header>
      )}

      {headerType === "diseño2" && (
        <header
          className="text-gray-600 body-font"
          style={{ backgroundColor: background }} // Fondo del navbar
        >
          <div className="container mx-auto flex flex-wrap px-5 py-3 flex-col md:flex-row items-center">
            <a
              className="flex title-font font-medium items-center text-gray-900 mb-4 md:!mb-0"
            >
              <LogoManager storeName={storeName} compressedLogo={compressedLogo} />
            </a>
            <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400 flex flex-wrap items-center text-base justify-center">
              {items
                .filter((item) => item.type !== "button")
                .map((item, index) => (
                  <a key={index} href={item.href} className="mr-5 hover:text-gray-900 ">
                    {item.label}
                  </a>
                ))}
            </nav>
            {items
              .filter((item) => item.type === "button")
              .map((item, index) => (
                <button
                  key={index}
                  className="inline-flex items-center ml-[20px] bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:!mt-0"
                  style={{
                    backgroundColor: item.buttonColor || "#4f46e5", // Fondo del botón
                    color: item.textColor || "white", // Color de letra del botón
                  }}
                  onClick={() => window.location.href = item.href}
                >
                  {item.label}
                </button>
              ))}
          </div>
        </header>
      )}

      {headerType === "diseño3" && (
        <header
          className="text-gray-600 body-font"
          style={{ backgroundColor: background }} // Fondo del navbar
        >
          <div className="container mx-auto flex flex-wrap px-5 py-3 flex-col md:flex-row items-center">
            <a
              className="flex title-font font-medium items-center text-gray-900 mb-4 md:!mb-0"
            >
              <LogoManager storeName={storeName} compressedLogo={compressedLogo} />
            </a>
            <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
              {items
                .filter((item) => item.type !== "button")
                .map((item, index) => (
                  <a key={index} href={item.href} className="mr-5 hover:text-gray-900">
                    {item.label}
                  </a>
                ))}
            </nav>
            {items
              .filter((item) => item.type === "button")
              .map((item, index) => (
                <button
                  key={index}
                  className="inline-flex items-center ml-[20px] bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:!mt-0"
                  style={{
                    backgroundColor: item.buttonColor || "#4f46e5", // Fondo del botón
                    color: item.textColor || "white", // Color de letra del botón
                  }}
                  onClick={() => window.location.href = item.href}
                >
                  {item.label}
                </button>
              ))}
          </div>
        </header>
      )}

      {headerType === "diseño4" && (
        <header
          className="text-gray-600 body-font"
          style={{ backgroundColor: background }} // Fondo del navbar
        >
          <div className="container mx-auto flex flex-wrap px-5 py-3 flex-col md:flex-row items-center">
            <nav className="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
              {items
                .filter((item) => item.type !== "button")
                .map((item, index) => (
                  <a key={index} href={item.href} className="mr-5 hover:text-gray-900">
                    {item.label}
                  </a>
                ))}
            </nav>
            <a
              className="flex order-first lg:!order-none lg:w-1/5 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center mb-4 md:!mb-0"
            >
              <LogoManager storeName={storeName} compressedLogo={compressedLogo} />
            </a>
            <div className="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
              {items
                .filter((item) => item.type === "button")
                .map((item, index) => (
                  <button
                    key={index}
                    className="inline-flex items-center ml-[20px] bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:!mt-0"
                    style={{
                      backgroundColor: item.buttonColor || "#4f46e5", // Fondo del botón
                      color: item.textColor || "white", // Color de letra del botón
                    }}
                    onClick={() => window.location.href = item.href}
                  >
                    {item.label}
                  </button>
                ))}
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default NavbarPreview;
