import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import LogoForm from './components/LogoForm';
import LogoEditButton from './components/LogoEditButton';
import LogoPreview from './components/LogoPreview';
import decompressLogo from './utils/decompressLogo';
import { saveToFirestore } from '../../utils/firebaseUtils';
import { auth } from '../../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';

// Función para determinar si el diseño es móvil o responsivo
const isMobileOrResponsive = () => {
  const isMobileDevice = /Mobi|Android|iPhone/i.test(navigator.userAgent); // Detectar dispositivo móvil
  const isResponsive = window.innerWidth <= 995; // Considerar ancho <= 768px como "móvil"
  return isMobileDevice || isResponsive;
};


const LogoManager = ({ storeName = "localhost" , compressedLogo }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [logoData, setLogoData] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [user, setUser] = useState(null); 

  useEffect(() => {
    const checkMobile = () => setIsMobile(isMobileOrResponsive());
    checkMobile(); // Validar al montar
    window.addEventListener('resize', checkMobile); // Escuchar cambios de tamaño de ventana
    return () => window.removeEventListener('resize', checkMobile); // Limpiar evento al desmontar
  }, []);


  useEffect(() => {
    if (compressedLogo) {
      try {
        const decompressedData = decompressLogo(compressedLogo);
        setLogoData(decompressedData);
      } catch (error) {
        console.error("Error al descomprimir el logo:", error);
      }
    }
  }, [compressedLogo]);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);


  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const handleSaveLogo = async (compressedLogoData) => {
    const logoData = compressedLogoData;
    await saveToFirestore(storeName, 'config', { logoData });

    const decompressedData = decompressLogo(compressedLogoData);

    setLogoData(decompressedData);
  };
  

  return (
    <Box>
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
      >
        <LogoPreview  logo={isMobile ? logoData.mobile || '' : logoData.desktop || ''}
                      width={isMobile ? logoData.mobileWidth : logoData.desktopWidth}
                      height={isMobile ? logoData.mobileHeight : logoData.desktopHeight} />
        <>
        {user && (
          <LogoEditButton onClick={handleOpenDialog} />
        )}
        </>

      </Box>

      <LogoForm
        storeName={storeName}
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveLogo}
        currentLogo={logoData}
      />
    </Box>
  );
};

export default LogoManager;
