// src/components/MainBanner3.js
import React, { useState, useEffect } from 'react';
import { saveToFirestore, getAuthenticatedUser, uploadFile } from '../../../../utils/firebaseUtils';
import LZString from 'lz-string';
import BannerSkeleton2 from '../BannerSkeleton2';
import { auth } from '../../../../firebaseConfig';
import { useNavigate , useLocation , useParams} from 'react-router-dom'; 
import BannerEditorModal from '../BannerEditorModal';
import BannerEditButton from '../BannerEditButton';


const MainBanner3 = ({ compressedBanner,refreshStoreConfig,storeName }) => {

  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  // Valores por defecto para bannerData
  const defaultBannerData = {
    title: 'Controlada para Todos',
    subtitle: '¡Ropa hecha para ti!',
    description:
      'Plataforma intuitiva, escalable, segura y unificada para la visualización, descarga y edición de reportes y documentos.',
    image: 'assets/img/default-banner.jpg',
    image2: 'assets/img/default-banner.jpg',
    logo: 'assets/img/default-banner.jpg',
    buttonText: 'Comenzar ahora',
    buttonText2: 'Comenzar ahora 2',
    buttonColor: '#1976d2',
    buttonTextColor: '#ffffff',
    buttonAction: 'https://example.com',
    subtitleColor: '#000000',
    titleColor: '#000000',
    descriptionColor: '#000000',
    bannerType: 'MainBanner3',
    imageMobile: 'assets/img/default-banner.jpg',
  };

  const [bannerData, setBannerData] = useState(defaultBannerData);
  const [previewImage, setPreviewImage] = useState(defaultBannerData.image);
  const [previewImage2, setPreviewImage2] = useState(defaultBannerData.image2);
  const [previewLogo, setPreviewLogo] = useState(defaultBannerData.logo);
  const [previewImageMobile, setPreviewImageMobile] = useState(defaultBannerData.imageMobile);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    //console.log('Compressed banner received2:', compressedBanner);
    if (compressedBanner) {
      try {
        const decompressedData = LZString.decompressFromBase64(compressedBanner);
        //console.log('Decompressed banner data2:', decompressedData);
        if (decompressedData) {
          const parsedData = JSON.parse(decompressedData);
          //console.log('Parsed banner data2:', parsedData);
          const bannerData = {
            ...defaultBannerData,
            ...parsedData,
            bannerType: parsedData.bannerType || defaultBannerData.bannerType,
          };
          setBannerData(bannerData);
          setPreviewImage(parsedData.image || defaultBannerData.image);
          setPreviewImage2(parsedData.image2 || defaultBannerData.image2);
          setPreviewImageMobile(bannerData.imageMobile || defaultBannerData.imageMobile);
          setPreviewLogo(parsedData.logo || defaultBannerData.logo);
        } else {
          console.error('Error al descomprimir el banner: decompressedData es null.');
        }
      } catch (error) {
        console.error('Error al descomprimir el banner:', error);
      }
    } else {
      //console.log('No se recibió compressedBanner2.');
      setBannerData(defaultBannerData);
      setPreviewImage(defaultBannerData.image);
      setPreviewImage2(defaultBannerData.image2);
      setPreviewLogo(defaultBannerData.logo);
      setPreviewImageMobile(defaultBannerData.imageMobile);
    }
  }, [compressedBanner]);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => setIsMobileView(mediaQuery.matches);

    handleResize(); // Establece el valor inicial
    mediaQuery.addEventListener('change', handleResize); // Escucha cambios

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(`Change detected - Name2: ${name}, Value: ${value}`);
    setBannerData((prev) => ({ ...prev, [name]: value }));
    //console.log('Updated bannerData2:', bannerData);
  };

  /*const handleImageChange = (e, imageKey) => {
    const file = e.target.files[0];
    if (file) {
        const imageUrl = URL.createObjectURL(file);
        if (imageKey === 'image') {
          setPreviewImage(imageUrl);
        } else if (imageKey === 'image2') {
            setPreviewImage2(imageUrl);
        } else if (imageKey === 'logo') {
          setPreviewLogo(imageUrl);
        }
        setBannerData((prev) => ({ ...prev, [imageKey]: file })); // Guarda el objeto File directamente
    }
  };*/

  const handleImageChange = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

      setBannerData((prev) => ({ ...prev, [imageType]: file })); 
      
      // Actualizamos la previsualización según el tipo
      if (imageType === 'image') {
        setPreviewImage(imageUrl);
      } else if (imageType === 'image2') {
        setPreviewImage2(imageUrl);
      } else if (imageType === 'imageMobile') {
        setPreviewImageMobile(imageUrl);
      } else if (imageType === 'logo') {
        setPreviewLogo(imageUrl);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      //console.log('Data to save in banner (including bannerType):', bannerData);
      const userEmail = getAuthenticatedUser();
      const userId = userEmail.replace('@', '_').replace('.', '_');
  
      let imageUrl = bannerData.image;
      
      // Verifica y sube la imagen principal (image)
      if (bannerData.image instanceof File) {
        imageUrl = await uploadFile(bannerData.image, storeName+'/banners/fondo');
      }
      
      let imageUrl2 = bannerData.image2;
      // Verifica y sube la imagen secundaria (image2)
      if (bannerData.image2 instanceof File) {
        imageUrl2 = await uploadFile(bannerData.image2, storeName+'/banners/secundario');
      }

      let logoUrl = bannerData.logo;
      if (bannerData.logo instanceof File) {
        logoUrl = await uploadFile(bannerData.logo, storeName+'/logo/image');
      }

      let imageUrlMobile = bannerData.imageMobile;
      if (bannerData.imageMobile instanceof File) {
        imageUrlMobile = await uploadFile(bannerData.imageMobile, storeName+'/banners/fondoMobile');
      }
  
      const bannerToSave = {
        ...bannerData,
        image: imageUrl,
        image2: imageUrl2,
        logo: logoUrl,
        imageMobile: imageUrlMobile,
      };
  
      // Convierte los datos del banner a una cadena JSON
      const bannerJson = JSON.stringify(bannerToSave);
  
      // Comprime la cadena JSON utilizando compressToBase64
      const banner = LZString.compressToBase64(bannerJson);
  
      //console.log('Banner comprimido:', banner);
  
      // Guarda en Firestore utilizando saveToFirestore
      await saveToFirestore(storeName, 'config', { banner });
  
      // Actualiza el estado local con los datos guardados
      setBannerData(bannerToSave);
      setPreviewImage(imageUrl);
      setPreviewImage2(imageUrl2);
      setPreviewLogo(logoUrl);
      setPreviewImageMobile(imageUrlMobile);
  
      //console.log('Banner guardado con éxito');
      handleClose();
  
      // Refresca la configuración de la tienda si la función está disponible
      if (refreshStoreConfig) {
        refreshStoreConfig();
      }
    } catch (error) {
      console.error('Error al guardar el banner:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const backgroundImage = isMobileView ? previewImageMobile : previewImage;

  return loading ? (
    <BannerSkeleton2 />
  ) : (
    <div
      className="w-full bg-cover bg-center relative flex flex-col h-[90vh] lg:!h-[85vh]"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* LOGO en pantallas grandes (posición absoluta) */}
      <div className="hidden lg:flex absolute top-6 left-6 lg:left-[73px] items-center">
        <img src={bannerData.logo} alt="Logo" className="w-[340px]" />
      </div>

      {/* LOGO en pantallas pequeñas (dentro del flujo, para evitar superposición) */}
      <div className="flex lg:hidden justify-center mt-6">
        <img src={bannerData.logo} alt="Logo" className="w-[150px]" />
      </div>

      <div
        className="w-full flex flex-col lg:flex-row items-center justify-start lg:justify-between relative flex-1 lg:pl-[73px] gap-2 lg:gap-[28px] xl:gap-2"
        style={{ flexGrow: 1 }}
      >
        {/* Sección de texto */}
        <div className="max-w-xl text-left flex flex-col items-start gap-[24px] p-8 lg:p-0 leading-7">
          <span
            className="text-[17px] lg:text-[20px]"
            style={{ color: bannerData.subtitleColor }}
          >
            {bannerData.subtitle}
          </span>

          <h1
            className="text-[36px] sm:text-[48px] leading-tight"
            style={{ color: bannerData.titleColor }}
          >
            {bannerData.title}
          </h1>

          <p
            className="text-[18px] sm:text-[20px] lg:text-[24px] leading-normal"
            style={{ color: bannerData.descriptionColor }}
          >
            {bannerData.description}
          </p>

          <a
            href={bannerData.buttonAction}
            className="btn rounded-[10px] text-[15px]"
            style={{
              backgroundColor: bannerData.buttonColor,
              color: bannerData.buttonTextColor,
            }}
          >
            {bannerData.buttonText}
          </a>
        </div>

        {/* Columna de imagen secundaria (solo se ve en pantallas >= lg) */}
        <div className="h-auto md:h-[605px] xl:h-auto lg:flex items-center justify-center">
          <img
            src={bannerData.image2 || defaultBannerData.image2}
            alt="Imagen Secundaria del Banner"
            className="w-full h-full object-contain xl:w-[1000px]"
          />
        </div>

        {/* Botón y Modal de edición */}
        {user && <BannerEditButton onClick={handleOpen} />}

        <BannerEditorModal
          open={open}
          handleClose={handleClose}
          bannerData={bannerData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          loading={loading}
          bannerType="MainBanner3"
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};

export default MainBanner3;