import React from 'react';
import { Button } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const ServicioAddButton = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<DesignServicesIcon />}
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '2rem',
        right: '-6rem', // Posición inicial fuera de vista
        transition: 'right 0.3s ease', // Transición suave al modificar la propiedad "right"
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 'none',
        background: 'linear-gradient(90deg, #a000b8, #5724ff 20%, #003efa)', // Aplicar gradiente
        color: 'white', // Asegurar que el texto sea visible
        '&:hover': {
          right: '0rem', // Nueva posición al hacer hover
          background: 'linear-gradient(90deg, #a000b8, #5724ff 50%, #003efa)', // Reaplica gradiente en hover
        },
      }}
    >
      Servicios
    </Button>
  );
};

export default ServicioAddButton;
