import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Chip,
    Box,
    IconButton,
    Typography,
    Divider,
  } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'], // Agrega botones de imagen y video
        ['code'],
      ],
      handlers: {
        code: function () {
          const htmlCode = prompt('Ingresa el código HTML del iframe:');
          if (htmlCode) {
            const quill = this.quill;
            const range = quill.getSelection();
            quill.clipboard.dangerouslyPasteHTML(range.index, htmlCode); // Inserta el HTML sin escapar
          }
        },
        image: function () {
          const url = prompt('Ingresa la URL de la imagen:');
          if (url) {
            const quill = this.quill;
            const range = quill.getSelection();
            quill.insertEmbed(range.index, 'image', url); // Inserta la imagen como URL
          }
        },
        video: function () {
          const url = prompt('Ingresa la URL del video (YouTube, Vimeo, etc.):');
          if (url) {
            const quill = this.quill;
            const range = quill.getSelection();
            quill.insertEmbed(range.index, 'video', url); // Inserta el video como URL
          }
        },
      },
    },
};

const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
    'video',
  ];

const BlogModal = ({ isOpen, onClose, onSubmit, date, setDate, title, setTitle, description, setDescription, image, setImage, tag, setTag,shortDescription,setShortDescription }) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [tagInput, setTagInput] = useState('');
    const [isHtmlMode, setIsHtmlMode] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const [sanitizeError, setSanitizeError] = useState('');

    useEffect(() => {
        if (isHtmlMode) {
          setHtmlContent(description);
        }
      }, [isHtmlMode, description]);

    // Agregar un nuevo tag al presionar Enter
    const handleAddTag = (e) => {
        if (e.key === 'Enter' && tagInput.trim()) {
            e.preventDefault(); // Evitar que el formulario se envíe
            setTag((prevTags) => [...prevTags, tagInput.trim()]);
            setTagInput('');
        }
    };

    // Eliminar un tag
    const handleDeleteTag = (tagToDelete) => {
        setTag((prevTags) => prevTags.filter((t) => t !== tagToDelete));
    };

    // Verificar la validez del formulario
    useEffect(() => {
        const isValid = date && title && description && shortDescription && image && tag.length > 0;;
        setIsFormValid(isValid);
    }, [date, title, description, shortDescription, image, tag]);

    useEffect(() => {
        if (isOpen && tag.length === 0) {
            setTag(["General"]); // Establecer "General" si no hay tags
        }
    }, [isOpen, tag, setTag]);

    const handleQuillChange = (value) => {
        setDescription(value);
    };

    const handleHtmlChange = (e) => {
        const newHtml = e.target.value;
        // Sanitizar el contenido HTML
        const sanitizedHtml = DOMPurify.sanitize(newHtml, {
            ALLOWED_TAGS: [
            'b','i','em','strong','a','p','ul','ol','li','br','span','div','img','h1','h2','h3','h4','h5','h6','blockquote','pre','code','button','textarea','form','input','select','option'],
            ALLOWED_ATTR: ['href', 'title', 'src', 'alt', 'style', 'type','value','name','placeholder','disabled','readonly','checked','required'],
        });
    
        if (newHtml !== sanitizedHtml) {
          setSanitizeError('Se han eliminado scripts o contenido malicioso.');
        } else {
          setSanitizeError('');
        }
    
        setHtmlContent(sanitizedHtml);
        setDescription(sanitizedHtml);
    };

    const toggleHtmlMode = () => {
        if (isHtmlMode) {
          setDescription(htmlContent);
        }
        setIsHtmlMode(!isHtmlMode);
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Añadir Publicación</DialogTitle>
            <DialogContent>
                <TextField
                    label="Fecha"
                    type="date"
                    fullWidth
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                        max: new Date().toISOString().split("T")[0], // Limita la fecha máxima al día actual
                    }}
                    margin="dense"
                />
                <TextField
                    label="Título"
                    type="text"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="dense"
                />

                <TextField
                    label="Añadir Tag"
                    type="text"
                    fullWidth
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    onKeyDown={handleAddTag}
                    helperText="Presiona Enter para añadir un tag"
                    margin="dense"
                />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: '16px' }}>
                    {tag.map((t, index) => (
                        <Chip
                            key={index}
                            label={t}
                            onDelete={() => handleDeleteTag(t)}
                            color="primary"
                        />
                    ))}
                </Box>

                <TextField
                    label="Descripción corta"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                    margin="dense"
                />
                <Box>
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                    }}
                    >
                    <Typography variant="h6" component="h2" style={{ whiteSpace: 'nowrap' }}>
                        Descripción del Blog
                    </Typography>
                    <Button variant="outlined" onClick={toggleHtmlMode}>
                        {isHtmlMode ? 'Modo Visual' : 'Editar HTML'}
                    </Button>
                    </Box>
                    <Divider />

                    {isHtmlMode ? (
                        <TextField
                            value={htmlContent}
                            multiline
                            rows={10}
                            fullWidth
                            onChange={handleHtmlChange}
                            label="Edición HTML"
                            variant="outlined"
                            helperText={
                            sanitizeError
                                ? sanitizeError
                                : 'Edite el HTML directamente. Se eliminarán scripts automáticamente.'
                            }
                            error={!!sanitizeError}
                        />
                    ) : (
                    <ReactQuill
                        theme="snow"
                        value={description}
                        //value={htmlContent}
                        onChange={handleQuillChange}
                        modules={modules}
                        formats={formats}
                       
                        style={{  marginTop: '16px' }}
                    />
                    )}
                </Box>
                <input
                    accept="image/*"
                    type="file"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                            const validTypes = ['image/webp', 'image/jpeg'];
                            if (validTypes.includes(file.type)) {
                                setImage(file);
                            } else {
                                alert('Por favor selecciona un archivo en formato WebP o JPEG.');
                                e.target.value = ''; // Resetea el input si el archivo no es válido
                            }
                        }
                    }}
                    style={{ marginTop: '20px' }}
                />



                
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
                <Button
                    onClick={onSubmit}
                    color="primary"
                    variant="contained"
                    disabled={!isFormValid} // Deshabilitar el botón si el formulario no es válido
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BlogModal;