import React, { useState,useEffect } from 'react';
import { db, collection, addDoc, getDocs  } from '../../firebaseConfig'; // Importa la configuración de Firebase
import BlogDesing from "./components/BlogDesing"

const Blog = ({storeName,isAdminRoute,isTikTokEnabled}) => {
  
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, `${storeName}/blog/entry`));
                const postsFromFirestore = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const validPosts = postsFromFirestore.filter(post => post.date);

                // Ordenar los posts por fecha (más recientes primero)
                const sortedPosts = validPosts.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA;
                });

                setPosts(sortedPosts.slice(0, 6));
            } catch (error) {
                console.error("Error al obtener las publicaciones de Firestore:", error.message);
            }
        };

        fetchPosts();
    }, [storeName]);
    
    return (
        <>
        
            <BlogDesing isTikTokEnabled={isTikTokEnabled} posts={posts} storeName={storeName} setPosts={setPosts} isAdminRoute={isAdminRoute}/>                     
        
        </>
    );
};

export default Blog;
